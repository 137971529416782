import React from 'react';
import { Modal } from '@mui/material';
import classNames from 'classnames';
import c from '../quiz.module.scss';
import YourQuizScore from '../YourQuizScore';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
const QuizModal = ({
  open = false,
  handleClose,
  course_id = '',
  score = 0,
  setView,
}) => {
  const history = useHistory();
  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={open}
      onClose={handleClose}
    >
      <div
        className={classNames(
          'd-flex w-100 h-100 align-items-center justify-content-center'
        )}
      >
        <div className={classNames(c.modalWrapper, 'p-4')}>
          <YourQuizScore fromQuizModal={true} score={score} />
          <div className="row mt-3 justify-content-end">
            <div className="col-3">
              <Button
                variant="outlined"
                className="w-100"
                onClick={() => {
                  handleClose();
                  history.push(`/course/${course_id}`);
                }}
              >
                Done
              </Button>
            </div>
            <div className="col-4">
              {/* <Button
                variant="contained"
                className="w-100"
                onClick={() => {
                  handleClose();
                  setView('report');
                }}
              >
                Show More Results
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QuizModal;
