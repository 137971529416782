import React from 'react';
import c from '../quiz.module.scss';
import classNames from 'classnames';
import Headings from '../../../Shared/Headings';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import {
  YourQuizScoreOptions,
  YourQuizScoreData,
} from '../../../../helpers/Quiz/index';

const YourQuizScore = ({ fromQuizModal = false, score = 0 }) => {
  // Use a default value for score if it's undefined or invalid
  const validScore = isNaN(score) ? 0 : score;

  // Prepare the data for the Doughnut chart using the validScore
  const data = {
    labels: ['Score'],
    datasets: [
      {
        data: [100 - validScore, validScore],
        backgroundColor: ['#CFD8DC', '#00A89A'],
        borderColor: ['#CFD8DC', '#00A89A'],
        borderWidth: 1,
        cutout: '80%',
      },
    ],
  };

  return (
    <>
      <div
        className={classNames(
          fromQuizModal ? c.scoreModal : c.ScoreContainer,
          'p-2 me-1'
        )}
      >
        <div className="d-flex bd-highlight justify-content-between mt-3 mb-3">
          <div className="bd-highlight">
            <Headings headingType="h6" classToOverride="fw-bold p-2">
              Your Quiz Score
            </Headings>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5 position-relative">
          <div className="position-absolute d-flex h-100 w-100 justify-content-center align-items-center">
            <Headings
              headingType="h30"
              classToOverride={classNames(c.primaryColor, 'fw-bold p-2')}
            >
              {validScore.toFixed(1)}% {/* Display the valid score */}
            </Headings>
          </div>
          <div className={classNames(c.chartContainer)}>
            <Doughnut data={data} options={YourQuizScoreOptions} />
          </div>
        </div>
      </div>
    </>
  );
};

export default YourQuizScore;
