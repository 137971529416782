import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import ManageCourses from '../../components/ManageCourses';
import CourseContent from '../../components/ManageCourses/CourseContent';
import Headings from '../../components/Shared/Headings';
import manageContent from './manageContent.module.scss';
import classNames from 'classnames';
import { getAllCourses } from '../../helpers/Content';
import { fetchCategories } from '../../helpers/LearningPlans/LearningPlansModalBox';
import { googleAnalyticsTriggerFn } from '../../utils/utility';
import { parse } from 'papaparse'; // For CSV parsing
import * as XLSX from 'xlsx';

const ManageContent = () => {
  const [activeComponent, setActiveComponent] = useState('manageCourse');
  const [newCourse, setNewCourse] = useState({
    client_id: '', // Set these to real IDs or keep as placeholders
    category_id: '',
    campaign_type_id: '',
    heading: '',
    description: '',
    author_id: '',
    is_public: true, // Default to true; change as needed
    corporate_id: '',
    cover_image_url: '',
    image_url: '',
    created_by: '',
    updated_by: '',
  });
  const [courseToEdit, setCourseToEdit] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(true);
  const [courses, setCourses] = useState([]);
  const [search, setSearch] = useState('');
  const [categories, setCategories] = useState([]);
  const [isHybridSearch, setIsHybridSearch] = useState(false);

  // Excel
  const [openUploadCourseModal, setOpenUploadCourseModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState([]);
  const [loadingPreview, setLoadingPreview] = useState(false);

  // Determine screen size to apply responsive styling
  const isResponsive = useMediaQuery('(max-width:600px)'); // Adjusts layout for screens smaller than 600px

  useEffect(() => {
    const getFetchCategories = async () => {
      const response = await fetchCategories();
      if (response?.data) {
        setCategories(response.data);
      }
    };
    getFetchCategories();
  }, []);

  useEffect(() => {
    const getCourses = async (searchValue = '') => {
      try {
        setLoading(true);
        const res = await getAllCourses(searchValue);
        setCourses(res);
      } catch (error) {
        console.error('Error fetching courses:', error);
      } finally {
        setLoading(false);
      }
    };
    getCourses();
  }, []);

  const handleOpenModal = () => {
    setNewCourse('');
    setOpenModal(true);
    setEdit(false);
  };

  const handleCourseCreation = (e) => {
    const courseName = e.target.value;
    setNewCourse((prevCourse) => ({
      ...prevCourse,
      heading: courseName,
    }));
  };

  const handleCourseNameChange = (courseName) => {
    if (edit) {
      setCourseToEdit((prevCourse) => ({
        ...prevCourse,
        heading: courseName,
      }));
    } else {
      setNewCourse((prevCourse) => ({
        ...prevCourse,
        heading: courseName,
      }));
    }
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleNext = () => {
    setActiveComponent('manageCourseContent');
    setOpenModal(false);
  };

  const onSearchChangeHandle = async (value, courseUpdate = false) => {
    const searchVal = typeof value === 'string' ? value : value?.name || '';
    setSearch(searchVal);

    if (courseUpdate) {
      const localMatches = courses.filter(
        (course) =>
          course.heading.toLowerCase().includes(searchVal.toLowerCase()) ||
          course.campaign_category
            .toLowerCase()
            .includes(searchVal.toLowerCase())
      );

      setCourses(localMatches);

      setLoading(true);
      setIsHybridSearch(true);

      try {
        const backendMatches = await getAllCourses(searchVal);
        const combinedResults = [
          ...localMatches,
          ...backendMatches.filter(
            (backendCourse) =>
              !localMatches.some(
                (localCourse) => localCourse.id === backendCourse.id
              )
          ),
        ];
        setCourses(combinedResults);
      } catch (error) {
        console.error('Error fetching courses:', error);
      } finally {
        setLoading(false);
        setIsHybridSearch(false);
      }

      googleAnalyticsTriggerFn(
        'Search',
        'Ahura Courses',
        'Ahura Courses searched on content page'
      );
    }
  };

  const renderHeader = () => {
    if (activeComponent === 'manageCourse') {
      return (
        <div
          className="d-flex align-items-center mb-4"
          style={{
            justifyContent: 'flex-start',
            gap: '50px',
            width: '100%',
            flexDirection: isResponsive ? 'column' : 'row',
          }}
        >
          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 150 }}
          >
            <InputLabel>Filter</InputLabel>
            <Select label="Filter">
              <MenuItem value="Only me">Only me</MenuItem>
              <MenuItem value="Organization">Organization</MenuItem>
              <MenuItem value="Anyone with link">Anyone with link</MenuItem>
            </Select>
          </FormControl>

          <Box
            sx={{
              display: 'flex',
              flexDirection: isResponsive ? 'column' : 'row',
              gap: isResponsive ? '10px' : '20px',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleOpenModal}
            >
              Create Course
            </Button>
            <Button
              variant="outlined"
              startIcon={<UploadIcon />}
              onClick={handleOpenSheetModal}
            >
              Upload Course
            </Button>
          </Box>

          <Box
            component="form"
            sx={{
              marginLeft: 'auto',
            }}
            noValidate
            autoComplete="off"
          >
            <Autocomplete
              freeSolo
              id="Search"
              getOptionLabel={(option) =>
                typeof option === 'string' ? option : option?.name
              }
              options={categories}
              sx={{ width: '300px' }}
              size="small"
              value={search}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  placeholder="Search courses..."
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      width: '300px',
                      borderRadius: 20,
                    },
                    endAdornment: (
                      <>
                        {(search && loading) ||
                        (isHybridSearch && courses.length > 0) ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      onSearchChangeHandle(search, true);
                    }
                  }}
                />
              )}
              onInputChange={(_, value) => {
                onSearchChangeHandle(value);
              }}
              onChange={(_, value) => {
                onSearchChangeHandle(value, true);
              }}
            />
          </Box>
        </div>
      );
    }
    return (
      <div className="d-flex align-items-center mb-4">
        <IconButton
          onClick={() => setActiveComponent('manageCourse')}
          style={{ marginRight: '10px' }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Headings
          classToOverride={classNames(manageContent.primaryColor, 'fw-bold')}
          headingType="h3"
        >
          {edit ? courseToEdit.heading : newCourse.heading}
        </Headings>
      </div>
    );
  };

  const handleOpenSheetModal = () => {
    setOpenUploadCourseModal(true);
  };

  // Close the Upload Course Modal
  const handleCloseUploadCourseModal = () => {
    setOpenUploadCourseModal(false);
    setSelectedCategory('');
    setSelectedFile(null);
    setFilePreview([]);
  };

  // Handle Category Change
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  // Handle File Change (CSV/Excel)
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    if (file) {
      setLoadingPreview(true);
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileType = file.name.split('.').pop().toLowerCase();

        let data;
        if (fileType === 'csv') {
          data = e.target.result;
          parse(data, {
            complete: (result) => {
              console.log('Parsed CSV Result:', result); // Log the result data
              const parsedData = result.data;
              if (Array.isArray(parsedData) && parsedData.length > 0) {
                // Handle the case where the CSV is parsed as an array of objects
                const header = Object.keys(parsedData[0]); // Get column headers from the first object
                const categoryColumnIndex = header.indexOf('Category ID'); // Find "Category ID" column index

                // Replace the "Category ID" with the selected category
                const modifiedData = parsedData.map((row, index) => {
                  if (index === -1) return row; // Skip header row
                  row['Category ID'] = selectedCategory; // Replace Category ID with selected category
                  return row;
                });

                setFilePreview(modifiedData); // Set the preview data
              } else {
                setFilePreview([]); // Clear preview if data structure is invalid
              }
              setLoadingPreview(false);
            },
            header: true, // Expecting headers in the CSV file
          });
        } else if (['xlsx', 'xls'].includes(fileType)) {
          const workbook = XLSX.read(e.target.result, { type: 'binary' });
          const sheetName = workbook.SheetNames[0]; // Preview first sheet
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          console.log('Parsed Excel Data:', json); // Log the Excel data
          if (Array.isArray(json) && json.length > 0) {
            const header = json[0]; // Headers row
            const categoryColumnIndex = header.indexOf('Category ID'); // Find the "Category ID" column index

            const modifiedData = json.map((row, index) => {
              if (index === 0) return row; // Skip header row
              row[categoryColumnIndex] = selectedCategory; // Replace Category ID with selected category
              return row;
            });

            setFilePreview(modifiedData); // Set the preview data
          } else {
            setFilePreview([]); // Clear preview if data structure is invalid
          }

          setLoadingPreview(false);
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  // Handle Upload (when the file and category are selected)
  const handleUpload = () => {
    if (!selectedCategory || !selectedFile) {
      alert('Please select a category and upload a file.');
      return;
    }

    // Handle the upload logic here
    console.log('Uploading file for category:', selectedCategory);
    handleCloseUploadCourseModal();
  };

  return (
    <div className="bg-white py-4 px-5">
      {renderHeader()}

      {/* Upload Course Modal */}
      <Dialog
        open={openUploadCourseModal}
        onClose={handleCloseUploadCourseModal}
      >
        <DialogTitle>Upload Course</DialogTitle>
        <DialogContent>
          {/* Category Selection */}
          <FormControl fullWidth margin="normal">
            <InputLabel>Category</InputLabel>
            <Select
              value={selectedCategory}
              onChange={handleCategoryChange}
              label="Category"
            >
              <MenuItem value="Category 1">Category 1</MenuItem>
              <MenuItem value="Category 2">Category 2</MenuItem>
              <MenuItem value="Category 3">Category 3</MenuItem>
            </Select>
          </FormControl>
          {/* File Upload */}
          <Box
            sx={{
              margin: '20px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <InputLabel
              htmlFor="upload-file"
              sx={{
                fontWeight: 'bold',
                color: '#333',
                marginBottom: '8px',
                fontSize: '16px',
              }}
            >
              Upload CSV/Excel
            </InputLabel>
            <input
              type="file"
              id="upload-file"
              onChange={handleFileChange}
              accept=".csv, .xlsx, .xls"
              style={{
                width: '100%',
                padding: '12px',
                fontSize: '16px',
                borderRadius: '8px',
                backgroundColor: '#fafafa',
                border: '1px solid #ccc',
                cursor: 'pointer',
                marginBottom: '20px',
              }}
            />
          </Box>

          {/* Loading Indicator */}
          {loadingPreview && (
            <CircularProgress
              size={24}
              style={{ margin: '20px auto', display: 'block' }}
            />
          )}

          {/* File Preview */}
          {filePreview.length > 0 && Array.isArray(filePreview[0]) && (
            <div
              style={{
                marginTop: '20px',
                maxHeight: '300px',
                overflowY: 'auto',
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: 'bold', marginBottom: '8px' }}
              >
                File Preview:
              </Typography>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    {filePreview[0].map((header, index) => (
                      <th
                        key={index}
                        style={{ padding: '8px', border: '1px solid #ddd' }}
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filePreview.slice(1).map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td
                          key={cellIndex}
                          style={{
                            padding: '8px',
                            border: '1px solid #ddd',
                            textAlign: 'center',
                          }}
                        >
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUploadCourseModal}>Cancel</Button>
          <Button
            onClick={handleUpload}
            disabled={!selectedCategory || !selectedFile}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <div className="content-area">
        {activeComponent === 'manageCourse' ? (
          <ManageCourses
            setCourses={setCourses}
            courses={courses}
            loading={loading}
            isHybridSearch={isHybridSearch && courses.length === 0}
            setActiveComponent={setActiveComponent}
            courseToEdit={setCourseToEdit}
            setLoading={setLoading}
          />
        ) : (
          <CourseContent
            edit={edit}
            setEdit={setEdit}
            course={edit ? courseToEdit : newCourse} // Pass newCourse to CourseContent or CourseSyllabus
            onChangeCourseName={handleCourseNameChange}
            categories={categories}
            setCategories={setCategories}
            setCourseToEdit={setCourseToEdit}
          />
        )}
      </div>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Enter New Course Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Course Name"
            type="text"
            fullWidth
            value={newCourse.heading}
            onChange={handleCourseCreation}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleNext} disabled={!newCourse?.heading?.trim()}>
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageContent;
