import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SelectTopic from '../../components/Home/SelectTopic';
import c from './info.module.scss';
import WelcomeSection from '../../components/Shared/WelcomeSection';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import Text from '../../components/Shared/Text';
import { googleAnalyticsTriggerFn } from '../../utils/utility';
import { Resources } from '../../config/Resources';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AddIcon from '@mui/icons-material/Add'; // Import the plus icon

import {
  Modal,
  Box,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import CheckCircleIcon
import { subscribeFieldFocusWeekly } from '../../helpers/InfoPage/FieldFocusWeekly'; // Adjust the path accordingly
import { CustomIframe } from '../../components/Shared/CustomIframe';
// Simple email validation function
const validateEmail = (email) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};

const InfoPage = (props) => {
  const { email, user_name } =
    useSelector((state) => state?.generalInformation) || {};
  const [selectedCard, setSelectedCard] = useState('learning'); // State to track clicked card
  const [open, setOpen] = useState(false); // State to control modal visibility
  const [submitted, setSubmitted] = useState(false); // State to track submission
  const [formData, setFormData] = useState({
    first_name: '',
    email: '',
    industry: '',
    roleTitle: '',
    country: '',
    interests: [''], // Initialize with 4 empty strings for interests
  });

  const [emailError, setEmailError] = useState(false);
  const [selectedLink, setSelectedLink] = useState('');
  const history = useHistory();
  const [loading, setLoading] = useState(false); // Track loading state

  useEffect(() => {
    const updateFormData = (field, value) => {
      setFormData((prevData) => ({
        ...prevData,
        [field]: value ? value.trim().split(' ')[0] : '', // For first_name, split by space; otherwise, empty string
      }));
    };

    updateFormData('first_name', user_name); // Update first_name
    updateFormData('email', email); // Update email
  }, [user_name, email]);

  console.log('Form Data So Far first_name:', formData.first_name); // Check if first_name is updated
  console.log('Form Data So Far email:', formData.email); // Check if email is updated

  // Handle modal open
  const handleOpen = () => setOpen(true);

  // Handle modal close
  const handleClose = () => setOpen(false);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('interest_')) {
      const index = parseInt(name.split('_')[1], 10);
      const updatedInterests = [...formData.interests];
      updatedInterests[index] = value;
      setFormData((prev) => ({ ...prev, interests: updatedInterests }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Add a new interest field (up to 4 interests)
  const handleAddInterest = () => {
    if (formData.interests.length < 4) {
      setFormData((prev) => ({
        ...prev,
        interests: [...prev.interests, ''],
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    setLoading(true); // Start loading when submit is clicked

    // Validate email before submission
    if (!validateEmail(formData.email)) {
      setEmailError(true); // Set error state if email is invalid
      return;
    }

    const submissionData = {
      first_name: formData.first_name,
      email: formData.email,
      industry: formData.industry,
      roleTitle: formData.roleTitle,
      country: formData.country,
      interest_one: formData.interests[0] || '',
      interest_two: formData.interests[1] || '',
      interest_three: formData.interests[2] || '',
      interest_four: formData.interests[3] || '',
    };

    try {
      // Call the subscribe API with the form data
      const response = await subscribeFieldFocusWeekly(submissionData);

      // Optionally, handle the response (e.g., display success message)
      if (response.message) {
        console.log('Subscription successful', response);
        setLoading(false); // Stop loading after submission
        setSubmitted(true); // Change state to show "Subscribed" message
      } else {
        console.log('Subscription failed', response);
        // Handle the failure case (e.g., show error message)
      }
    } catch (error) {
      setLoading(false); // Stop loading after submission
      console.error('Error subscribing:', error);
      // Handle errors (e.g., show error message)
    }
  };
  const { isTopicModal, profileCompletionStatus } = useSelector(
    (state) => state
  );

  const handleCardClick = (cardName) => {
    setSelectedCard(cardName); // Update the state with the clicked card
  };

  return (
    <>
      {isTopicModal ? (
        <SelectTopic />
      ) : (
        <div className={c.InfoPage}>
          <WelcomeSection />
          <div className={classNames('bg-white rounded', c.infoDetails)}>
            <div className="row align-items-center">
              <div className="col-7"></div>
            </div>

            {/* Container for avatar and supporting text/cards aligned to the right */}
            <div className={c.avatarTextContainer}>
              {/* Avatar on the left */}
              <div className={c.avatarContainer}>
                <img
                  alt="Ahura AI"
                  src={Resources.info.avatar} // Placeholder avatar URL
                  className={c.avatar}
                />
              </div>

              {/* Supporting text and cards to the right */}
              <div className={c.rightSideContent}>
                <Text>
                  <p className={c.supportingTextContent}>
                    I am Ahura AI. I'll be your learning companion as you
                    navigate this fast-changing world of skills and tools in the
                    age of AI.
                  </p>
                  <p className={c.supportingTextContent}>
                    How best can I support you?
                  </p>
                </Text>

                {/* Action Cards - Inside the same parent container */}
                <div className={c.cardContainer}>
                  {/* Highlight the selected card by dynamically applying a different background color */}
                  <Card
                    sx={{
                      backgroundColor:
                        selectedCard === 'tools'
                          ? 'rgb(0, 228, 154)'
                          : 'rgb(252, 229, 205)',
                      height: { xs: '240px', sm: '250px', lg: '300px' },
                      width: { xs: '100%', sm: '200px', lg: '300px' },
                      borderRadius: 2,
                      boxShadow: 3,
                      cursor: 'pointer',
                      transition:
                        'transform 0.3s ease-in-out, background-color 0.3s ease, border 0.3s ease',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border:
                        selectedCard === 'tools' ? '2px solid #4caf50' : 'none',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        border: '2px solid rgb(0, 168, 154)',
                      },
                    }}
                    onClick={() => handleCardClick('tools')}
                  >
                    <CardContent
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '20px', // Apply font-size to the div element
                        }}
                      >
                        Email me the latest AI tools in my field each week
                      </div>
                    </CardContent>
                  </Card>

                  <Card
                    sx={{
                      backgroundColor:
                        selectedCard === 'learning'
                          ? 'rgb(0, 228, 154)'
                          : 'rgb(252, 229, 205)',
                      height: { xs: '240px', sm: '250px', lg: '300px' },
                      width: { xs: '100%', sm: '200px', lg: '300px' },
                      borderRadius: 2,
                      boxShadow: 3,
                      cursor: 'pointer',
                      transition:
                        'transform 0.3s ease-in-out, background-color 0.3s ease, border 0.3s ease',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border:
                        selectedCard === 'learning'
                          ? '2px solid #4caf50'
                          : 'none',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        border: '2px solid rgb(0, 168, 154)',
                      },
                    }}
                    onClick={() => handleCardClick('learning')}
                  >
                    <CardContent
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '20px', // Apply font-size to the div element
                        }}
                      >
                        Log my daily learning efforts and reduce my distractions
                      </div>
                    </CardContent>
                  </Card>

                  <Card
                    sx={{
                      backgroundColor:
                        selectedCard === 'content'
                          ? 'rgb(0, 228, 154)'
                          : 'rgb(252, 229, 205)',
                      height: { xs: '240px', sm: '250px', lg: '300px' },
                      width: { xs: '100%', sm: '200px', lg: '300px' },
                      borderRadius: 2,
                      boxShadow: 3,
                      cursor: 'pointer',
                      transition:
                        'transform 0.3s ease-in-out, background-color 0.3s ease, border 0.3s ease',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border:
                        selectedCard === 'content'
                          ? '2px solid #4caf50'
                          : 'none',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        border: '2px solid rgb(0, 168, 154)',
                      },
                    }}
                    onClick={() => handleCardClick('content')}
                  >
                    <CardContent
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '20px', // Apply font-size to the div element
                        }}
                      >
                        Make my learning content more exciting
                      </div>
                    </CardContent>
                  </Card>

                  <Card
                    sx={{
                      backgroundColor:
                        selectedCard === 'guidance'
                          ? 'rgb(0, 228, 154)'
                          : 'rgb(252, 229, 205)',
                      height: { xs: '240px', sm: '250px', lg: '300px' },
                      width: { xs: '100%', sm: '200px', lg: '300px' },
                      borderRadius: 2,
                      boxShadow: 3,
                      cursor: 'pointer',
                      transition:
                        'transform 0.3s ease-in-out, background-color 0.3s ease, border 0.3s ease',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border:
                        selectedCard === 'guidance'
                          ? '2px solid #4caf50'
                          : 'none',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        border: '2px solid rgb(0, 168, 154)',
                      },
                    }}
                    onClick={() => handleCardClick('guidance')}
                  >
                    <CardContent
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '20px', // Apply font-size to the div element (smaller font size)
                        }}
                      >
                        Alert me when my mentees need guidance and support
                      </div>
                    </CardContent>
                  </Card>

                  {/* Display content below based on clicked card */}
                  {selectedCard && (
                    <div className={c.cardContent}>
                      <div className={c.cardTextContent}>
                        {selectedCard === 'tools' && (
                          <>
                            <p>
                              Fine choice! Every week new AI tools are getting
                              released and existing tools are improving their
                              features. It is almost impossible to keep up
                              without help.
                            </p>
                            <p>
                              After you share information about your role and
                              interests, we'll curate a list for you to select
                              what tools you want to learn about monthly.
                            </p>
                            <p>
                              Remember to switch on Ahura study buddy while you
                              learn about them! Tutorial Video.
                            </p>

                            <p>
                              <Modal open={open} onClose={handleClose}>
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 400,
                                    bgcolor: 'background.paper',
                                    boxShadow: 24,
                                    p: 4,
                                  }}
                                >
                                  {!submitted ? (
                                    <>
                                      <Typography
                                        variant="h6"
                                        component="h2"
                                        sx={{ marginBottom: 2 }}
                                      >
                                        My Role and Interests - Form
                                      </Typography>

                                      {!formData.email && (
                                        <TextField
                                          fullWidth
                                          label="What is your email address?"
                                          variant="outlined"
                                          name="email"
                                          value={formData.email}
                                          onChange={handleChange}
                                          sx={{ marginBottom: 2 }}
                                          error={emailError}
                                          helperText={
                                            emailError
                                              ? 'Please enter a valid email address'
                                              : ''
                                          }
                                        />
                                      )}

                                      <TextField
                                        fullWidth
                                        label="What is your industry?"
                                        variant="outlined"
                                        name="industry"
                                        value={formData.industry}
                                        onChange={handleChange}
                                        sx={{ marginBottom: 2 }}
                                      />

                                      <TextField
                                        fullWidth
                                        label="What is your role title?"
                                        variant="outlined"
                                        name="roleTitle"
                                        value={formData.roleTitle}
                                        onChange={handleChange}
                                        sx={{ marginBottom: 2 }}
                                      />

                                      <TextField
                                        fullWidth
                                        label="In what country do you work?"
                                        variant="outlined"
                                        name="country"
                                        value={formData.country}
                                        onChange={handleChange}
                                        sx={{ marginBottom: 2 }}
                                      />

                                      {/* Render Interest fields */}
                                      {formData.interests.map(
                                        (interest, index) => (
                                          <Box
                                            key={`interest_${index}`}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <TextField
                                              fullWidth
                                              label={`Interest ${index + 1}`}
                                              variant="outlined"
                                              name={`interest_${index}`}
                                              value={interest}
                                              onChange={handleChange}
                                              sx={{
                                                marginBottom: 2,
                                                marginRight: 2,
                                              }}
                                            />
                                            {/* Add Plus Icon button for adding more interests */}
                                            {index ===
                                              formData.interests.length - 1 &&
                                              formData.interests.length < 4 && (
                                                <Button
                                                  variant="outlined"
                                                  onClick={handleAddInterest}
                                                  sx={{ height: '100%' }}
                                                >
                                                  <AddIcon />
                                                </Button>
                                              )}
                                          </Box>
                                        )
                                      )}

                                      <Button
                                        variant="contained"
                                        sx={{
                                          marginTop: 2,
                                          backgroundColor: 'rgb(201,218,248)',
                                          color: 'black',
                                          '&:hover': {
                                            backgroundColor: '#3f51b5',
                                          },
                                        }}
                                        onClick={handleSubmit}
                                        disabled={loading} // Disable the button while loading
                                      >
                                        {loading ? (
                                          <CircularProgress
                                            size={24}
                                            sx={{ color: 'black' }}
                                          />
                                        ) : (
                                          'Submit'
                                        )}
                                      </Button>
                                    </>
                                  ) : (
                                    <Box sx={{ textAlign: 'center' }}>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontWeight: 'bold',
                                          marginBottom: 2,
                                        }}
                                      >
                                        You have successfully subscribed!
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                              </Modal>

                              <Box
                                sx={{
                                  display: 'flex', // Use Flexbox to align buttons horizontally
                                  justifyContent: 'flex-start', // Align buttons to the left (or closer together)
                                  gap: '40px', // Reduced gap between buttons
                                  marginTop: 3, // Optional: Add margin to the top of the container
                                }}
                              >
                                <Button
                                  variant="contained" // Use contained button style for better visibility
                                  sx={{
                                    marginTop: 3, // Optional: Add spacing from elements above
                                    fontWeight: 'bold', // Make text bold
                                    backgroundColor: 'rgb(201,218,248)', // Apply custom RGB color for button background
                                    color: 'black', // Set text color to black
                                    '&:hover': {
                                      backgroundColor: '#3f51b5', // Optional: darker hover color for better visibility
                                    },
                                  }}
                                  onClick={handleOpen}
                                >
                                  Complete form: My Role and Interests
                                </Button>
                                <Button
                                  variant="contained" // Use contained button style for better visibility
                                  sx={{
                                    marginTop: 3, // Optional: Add spacing from elements above
                                    fontWeight: 'bold', // Make text bold
                                    backgroundColor: 'rgb(201,218,248)', // Apply custom RGB color for button background
                                    color: 'black', // Set text color to black
                                    '&:hover': {
                                      backgroundColor: '#3f51b5', // Optional: darker hover color for better visibility
                                    },
                                  }}
                                  onClick={() => {
                                    history.push('/home');
                                    googleAnalyticsTriggerFn(
                                      'Button',
                                      'Back',
                                      'Back button clicked on profile page'
                                    );
                                  }}
                                >
                                  Open Dashboard
                                </Button>
                              </Box>
                            </p>
                          </>
                        )}
                        {selectedCard === 'learning' && (
                          <>
                            <p>
                              Once you see just how many benefits happen when
                              you log your learning daily, you'll wish you'd
                              known this success secret from the start. But no
                              need to worry, with Ahura you can start this
                              expert learning trick right now!
                            </p>
                            <p>
                              You get to prove to yourself and your supervisors
                              on the effort you are spending learning, get to
                              make decisions where to increase or reduce your
                              learning effort based on your data and even
                              better, you get to waste less time because Ahura
                              AI notifies you any time it estimates that you
                              might be getting distracted or need additional
                              help.
                            </p>
                            <p>
                              Be sure to watch these tutorial videos on how to
                              switch on Ahura when you learn, and switch it off
                              when you finish.
                            </p>
                            <p>
                              <Box
                                sx={{
                                  display: 'flex', // Use Flexbox to align buttons horizontally
                                  justifyContent: 'flex-start', // Align buttons to the left (or closer together)
                                  gap: '40px', // Reduced gap between buttons
                                  marginTop: 3, // Optional: Add margin to the top of the container
                                  //                                   flexWrap: 'wrap', // Ensure buttons wrap if space is tight (optional)
                                }}
                              >
                                <Button
                                  variant="contained" // Use contained button style for better visibility
                                  sx={{
                                    marginTop: 3, // Optional: Add spacing from elements above
                                    fontWeight: 'bold', // Make text bold
                                    backgroundColor: 'rgb(201,218,248)', // Apply custom RGB color for button background
                                    color: 'black', // Set text color to black
                                    '&:hover': {
                                      backgroundColor: '#3f51b5', // Optional: darker hover color for better visibility
                                    },
                                  }}
                                  onClick={() =>
                                    setSelectedLink(
                                      'https://www.loom.com/embed/51be1eb8d4544a989fd81a27fd996b19'
                                    )
                                  }
                                >
                                  Learn how to get started with Ahura! [VIDEO]
                                </Button>
                                <Button
                                  variant="contained" // Use contained button style for better visibility
                                  sx={{
                                    marginTop: 3, // Optional: Add spacing from elements above
                                    fontWeight: 'bold', // Make text bold
                                    backgroundColor: 'rgb(201,218,248)', // Apply custom RGB color for button background
                                    color: 'black', // Set text color to black
                                    '&:hover': {
                                      backgroundColor: '#3f51b5', // Optional: darker hover color for better visibility
                                    },
                                  }}
                                  onClick={() => {
                                    history.push('/home');
                                    googleAnalyticsTriggerFn(
                                      'Button',
                                      'Back',
                                      'Back button clicked on profile page'
                                    );
                                  }}
                                >
                                  Open Dashboard
                                </Button>
                              </Box>
                            </p>
                          </>
                        )}
                        {selectedCard === 'content' && (
                          <>
                            <p>
                              We are excited to help you jumpstart your learning
                              with this new innovation in learning! We've found
                              that people look forward to learning when the
                              content is in the format they prefer. Ahura can
                              convert your learning content into text, audio and
                              video formats to get you excited about learning
                              again.
                            </p>
                            <p>Follow the steps below to use this feature.</p>
                            <p>
                              <Box
                                sx={{
                                  display: 'flex', // Use Flexbox to align buttons horizontally
                                  justifyContent: 'flex-start', // Align buttons to the left (or closer together)
                                  gap: '40px', // Reduced gap between buttons
                                  marginTop: 3, // Optional: Add margin to the top of the container
                                  flexWrap: 'wrap', // Ensure buttons wrap if space is tight (optional)
                                }}
                              >
                                <Button
                                  variant="contained" // Use contained button style for better visibility
                                  sx={{
                                    marginTop: 3, // Optional: Add spacing from elements above
                                    fontWeight: 'bold', // Make text bold
                                    backgroundColor: 'rgb(201,218,248)', // Apply custom RGB color for button background
                                    color: 'black', // Set text color to black
                                    '&:hover': {
                                      backgroundColor: '#3f51b5', // Optional: darker hover color for better visibility
                                    },
                                  }}
                                  onClick={() =>
                                    setSelectedLink(
                                      'https://www.loom.com/embed/4906935bf63948bcbcdc389ae99ed9b0'
                                    )
                                  }
                                >
                                  Tutorial Video: Converting content with Ahura
                                </Button>

                                <Button
                                  variant="contained" // Use contained button style for better visibility
                                  sx={{
                                    marginTop: 3, // Optional: Add spacing from elements above
                                    fontWeight: 'bold', // Make text bold
                                    backgroundColor: 'rgb(201,218,248)', // Apply custom RGB color for button background
                                    color: 'black', // Set text color to black
                                    '&:hover': {
                                      backgroundColor: '#3f51b5', // Optional: darker hover color for better visibility
                                    },
                                  }}
                                  onClick={() => {
                                    history.push('/home');
                                    googleAnalyticsTriggerFn(
                                      'Button',
                                      'Back',
                                      'Back button clicked on profile page'
                                    );
                                  }}
                                >
                                  Open Dashboard
                                </Button>
                              </Box>
                            </p>
                          </>
                        )}
                        {selectedCard === 'guidance' && (
                          <>
                            <p>
                              When your mentees or students are off to learn on
                              their own, it’s hard for you to support them in
                              those times or be aware of the reasons why they
                              might be struggling.
                            </p>
                            <p>
                              When they switch Ahura AI on, it provides them
                              notifications and advise whenever they are likely
                              distracted or struggling, and provides you alerts
                              and insights on how you can support them.
                            </p>
                            <p>
                              Check out our video on how to use your dashboard
                              and support more people!
                            </p>

                            <p>
                              <Box
                                sx={{
                                  display: 'flex', // Use Flexbox to align buttons horizontally
                                  justifyContent: 'flex-start', // Align buttons to the left (or closer together)
                                  gap: '40px', // Reduced gap between buttons
                                  marginTop: 3, // Optional: Add margin to the top of the container
                                  //                                   flexWrap: 'wrap', // Ensure buttons wrap if space is tight (optional)
                                }}
                              >
                                <Button
                                  variant="contained" // Use contained button style for better visibility
                                  sx={{
                                    marginTop: 3, // Optional: Add spacing from elements above
                                    fontWeight: 'bold', // Make text bold
                                    backgroundColor: 'rgb(201,218,248)', // Apply custom RGB color for button background
                                    color: 'black', // Set text color to black
                                    '&:hover': {
                                      backgroundColor: '#3f51b5', // Optional: darker hover color for better visibility
                                    },
                                  }}
                                  onClick={() =>
                                    setSelectedLink(
                                      'https://www.loom.com/embed/a1a46794d9ed4e9d813bf50d345f0bbc'
                                    )
                                  }
                                >
                                  Check out our Supervisor’s Guide for Ahura AI!
                                  [VIDEO]
                                </Button>

                                <Button
                                  variant="contained" // Use contained button style for better visibility
                                  sx={{
                                    marginTop: 3, // Optional: Add spacing from elements above
                                    fontWeight: 'bold', // Make text bold
                                    backgroundColor: 'rgb(201,218,248)', // Apply custom RGB color for button background
                                    color: 'black', // Set text color to black
                                    '&:hover': {
                                      backgroundColor: '#3f51b5', // Optional: darker hover color for better visibility
                                    },
                                  }}
                                  onClick={() => {
                                    history.push('/home');
                                    googleAnalyticsTriggerFn(
                                      'Button',
                                      'Back',
                                      'Back button clicked on profile page'
                                    );
                                  }}
                                >
                                  Open Dashboard
                                </Button>
                              </Box>
                            </p>
                          </>
                        )}
                      </div>
                      <CustomIframe
                        iconClass={c.cursorPointer}
                        selectedLink={selectedLink}
                        setSelectedLink={setSelectedLink}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InfoPage;
