import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TopBar from '../TopBar/TopBar';
import { getCommunityData } from '../../../../helpers/Home/Community';
import CommunityGridStructure from '../CommunityGridStructure/CommunityGridStructure';
import { CLIENT_ID } from '../../../../config/Constants';
const platform_id = CLIENT_ID;
const Community = ({ corpID }) => {
  const [uniFilter, setUniFilter] = useState('all');
  const [trendingFilter, setTrendingFilter] = useState('capability');
  const [topLearnersData, setTopLearnersData] = useState([]);
  const [trendingCoursesData, setTrendingCoursesData] = useState([]);
  const userData = useSelector((state) => {
    return state?.generalInformation;
  });
  const userCorpDetail = useSelector((state)=>  state?.userCorporateDetails)


  useEffect(() => {
    if (userData?.user_id) getCommunity();
  }, [trendingFilter, uniFilter, userData.user_id, corpID, userCorpDetail]);

  const getCommunity = async () => {
    const data = await getCommunityData(
      userData.user_id,
      uniFilter,
      trendingFilter,
      corpID,
      platform_id
    );
    setTopLearnersData(data?.top_learners_data);
    setTrendingCoursesData(data?.trending_data);
  };
  return (
    <div className="mt-5 p-5" style={{ backgroundColor: '#ffffff' }}>
      <TopBar uniFilter={uniFilter} setUniFilter={setUniFilter} />
      <CommunityGridStructure
        trendingFilter={trendingFilter}
        setTrendingFilter={setTrendingFilter}
        CommunityLearnersData={topLearnersData}
        TrendingCoursesData={trendingCoursesData}
      />
    </div>
  );
};

export default Community;
