import { API_URLS } from '../../../config/API_URLS/api_urls';
import { postApiCall, putApiCall } from '../../../utils/axios/axios';

// Function to subscribe to the Field Focus Weekly newsletter
export const subscribeFieldFocusWeekly = async (subscriptionData) => {
  const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
  const response = await postApiCall(
    API_URLS.subscribe_field_focus_weekly_subscription,
    subscriptionData,
    paramsData
  );
  return response;
};

// Function to unsubscribe from the Field Focus Weekly newsletter
export const unsubscribeFieldFocusWeekly = async (subscriptionData) => {
  const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
  const response = await putApiCall(
    API_URLS.unsubscribe_field_focus_weekly_subscription,
    subscriptionData,
    paramsData
  );
  return response;
};
