import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CircularProgress,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import Headings from '../../components/Shared/Headings';
import manageCourses from './manageCourses.module.scss';
import classNames from 'classnames';
import { deleteCourse } from '../../helpers/Content';

const ManageCourses = ({
  courses,
  setCourses,
  loading,
  isHybridSearch,
  setActiveComponent,
  courseToEdit,
  setLoading,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [filteredCourses, setFilteredCourses] = useState([]);

  // Sync the filtered courses with the main course list when `courses` changes
  useEffect(() => {
    setFilteredCourses([...courses]); // Ensure a new reference to trigger re-renders
  }, [courses]);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditCourse = (course) => {
    console.log(`Editing Course: ${JSON.stringify(course, null, 2)}`);
    setActiveComponent('manageCourseContent');
    courseToEdit(course);
  };

  const openDeleteDialog = (courseId) => {
    setCourseToDelete(courseId);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setCourseToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      setLoading(true)
      const response = await deleteCourse(courseToDelete);

      if (response) {
        console.log(`Course with ID ${courseToDelete} deleted successfully`);

        // Filter out the deleted course from the state
        const updatedCourses = courses.filter(
          (course) => course.id !== courseToDelete
        );

        // Update both the original course list and filtered courses
        setCourses(updatedCourses);
        setFilteredCourses(updatedCourses); // Reset search results immediately
      } else {
        console.error('Error deleting course:', response.message);
      }

    } catch (error) {
      console.error('Error deleting course:', error);
    } finally {
      closeDeleteDialog();
      setLoading(false)
    }
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, filteredCourses.length - page * rowsPerPage);

  const render404 = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '500px',
        textAlign: 'center',
      }}
    >
      <Typography variant="h3" color="error" gutterBottom>
        404 - No Courses Found
      </Typography>
      <Typography variant="body1">
        We couldn’t find any courses matching your search. Please try again with
        a different query.
      </Typography>
    </Box>
  );

  return (
    <div className={classNames('manageCourses', manageCourses.primaryColor)}>
      <Headings classToOverride="fw-bold" headingType="h2">
        Courses
      </Headings>

      {(loading && filteredCourses.length === 0) || isHybridSearch ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '500px',
          }}
        >
          <CircularProgress size={150} color="primary" />
        </Box>
      ) : filteredCourses.length === 0 ? (
        render404()
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: 'left' }}>Name</TableCell>
                  <TableCell style={{ textAlign: 'left' }}>Category</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCourses
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((course) => (
                    <TableRow key={course.id}>
                      <TableCell style={{ textAlign: 'left' }}>
                        {course.heading}
                      </TableCell>
                      <TableCell style={{ textAlign: 'left' }}>
                        {course.campaign_category}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '8px',
                            borderRadius: '8px',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            boxShadow:
                              'inset 0 2px 4px rgba(128, 128, 128, 0.5)',
                            width: '120px',
                            margin: '0 auto',
                          }}
                        >
                          <EditIcon
                            sx={{ cursor: 'pointer', color: 'grey' }}
                            onClick={() => handleEditCourse(course)}
                          />
                          <ShareIcon
                            sx={{ cursor: 'pointer', color: 'grey' }}
                            onClick={() =>
                              console.log(`Share ${course.heading}`)
                            }
                          />
                          <DeleteIcon
                            sx={{ cursor: 'pointer', color: 'grey' }}
                            onClick={() => openDeleteDialog(course.id)}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={3} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredCourses.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this course? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancel</Button>
          <Button color="error" onClick={handleConfirmDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageCourses;
