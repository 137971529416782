/**
 * @function Resources
 * @params {none}
 * @exports {string} Object
 * @returns url of images png and svg present in image/common/folder
 */
const base = '/images';
const commonBase = '/images/common';

export const Resources = {
  images: {
    AhuraAiIcon: `${commonBase}/icons/ahura-ai-logo.svg`,
    AhuraHeaderLogo: `${commonBase}/Logos/Ahura-Logo.svg`,
    AhuraHeaderLogo2: `${commonBase}/Logos/ahura-logo-2.png`,
    InfoBubbleIcon: `${commonBase}/icons/Dailogue.svg`,
    SignUpHomeIcon: `${commonBase}/icons/SignupHomeIcon.svg`,
    ChatBubbleIcon: `${commonBase}/icons/ChatBubble.svg`,
    FacebookTextIcon: `${commonBase}/icons/FacebookTextIcon.svg`,
    ChapterInProgress: `${commonBase}/icons/ChapterInProgress.svg`,
    OpenFolder: `${commonBase}/icons/OpenFolder.svg`,
    GoogleTextIcon: `${commonBase}/icons/GoogleTextIcon.svg`,
    LinkedinTextIcon: `${commonBase}/icons/LinkedinTextIcon.svg`,
    FormEditIcon: `${commonBase}/icons/FormEdit.svg`,
    AvtarImage: `${commonBase}/AvtarImage.png`,
    AhuraCreatorLogo: `${commonBase}/Logos/CreatorProfileLogo.svg`,
    ChromeExtention: `${commonBase}/Logos/chromeExtension.png`,
    SignupCorporate: `${commonBase}/Logos/SignupCorporate.svg`,
    SignupSuccessGraphic: `${commonBase}/Logos/SignupSuccessGraphic.svg`,
    Ahura: `${commonBase}/Logos/Ahura.png`,
    AhuraHeaderImage: `${commonBase}/ahuraLogo.svg`,
    LandingPage: `${commonBase}/LandingPage/landingPage.jpg`,
    LandingPageCourseIcon: `${commonBase}/LandingPage/ahura-ai-logo_transparent.png`,
    LandingPageVideoImage: `${commonBase}/LandingPage/video-cover-page.png`,
    LandingTestimonial: `${commonBase}/LandingPage/ahura_testimonial_img.png`,
    TrophyIcon: `${commonBase}/icons/trophy-icon.svg`,
    GaugeColorIcon: `${commonBase}/icons/GaugeColorIcon.svg`,
    home: {
      stats: `${base}/Home/stats.svg`,
      javaScript: `${base}/Home/js.svg`,
      python: `${base}/Home/python.svg`,
      pythonBlue: `${base}/Home/PythonBlue.svg`,
      peopleGroup: `${base}/Home/peopleGroup.svg`,
      FaceMovement: `${base}/Home/FaceMovementimage.png`,
      community1: `${base}/Home/community1.svg`,
      community2: `${base}/Home/community2.svg`,
      community3: `${base}/Home/community3.svg`,
      google: `${base}/Home/google.svg`,
      udemy: `${base}/Home/udemy.svg`,
      CalibrationData: `${base}/Home/CalibrationData.png`,
      TopLearners1: `${base}/Home/TopLearners1.svg`,
      TopLearners2: `${base}/Home/TopLearners2.svg`,
      TopLearners3: `${base}/Home/TopLearners3.svg`,
      TopLearners4: `${base}/Home/TopLearners4.svg`,
      TopLearners5: `${base}/Home/TopLearners5.svg`,
      StudyTimeClock: `${base}/Home/StudyTimeClock.svg`,
      AverageScore: `${base}/Home/AverageScore.svg`,
      clockCommunity: `${base}/Home/clockCommunity.svg`,
      MoreInfo: `${base}/Home/more_info.png`,
    },
    Corporate: `${commonBase}/Logos/Corporate.svg`,
    // Signup Interest Page Icons
    Python: `${commonBase}/icons/Signup_InterestPageIcons/Python.svg`,
    CleanEnergy: `${commonBase}/icons/Signup_InterestPageIcons/CleanEnergy.svg`,
    Economics: `${commonBase}/icons/Signup_InterestPageIcons/Economics.svg`,
    FrontEnd: `${commonBase}/icons/Signup_InterestPageIcons/FrontEnd.svg`,
    GreenTech: `${commonBase}/icons/Signup_InterestPageIcons/GreenTech.svg`,
    Management: `${commonBase}/icons/Signup_InterestPageIcons/Management.svg`,
    Marketing: `${commonBase}/icons/Signup_InterestPageIcons/Marketing.svg`,
    Science: `${commonBase}/icons/Signup_InterestPageIcons/Science.svg`,
    Loadings: `${commonBase}/Logos/Loadings.gif`,
    Therapy: `${commonBase}/icons/Signup_InterestPageIcons/Therapy.svg`,
    //Signup Face Calibration Images
    FaceCalibrationFrame: `${commonBase}/FaceCalibration/Frame.svg`,
    FrameCorders: `${commonBase}/FaceCalibration/FrameCorners.svg`,
    InialScanProcess: `${commonBase}/FaceCalibration/InitialScanProcess.png`,
    FaceCalibrationNeutral: `${commonBase}/FaceCalibration/FaceCalibrationNeutral.png`,
    FaceCalibrationHappy: `${commonBase}/FaceCalibration/FaceCalibrationHappy.png`,
    FaceCalibrationRotation: `${commonBase}/FaceCalibration/FaceCalibrationRotation.png`,
    FaceCalibrationFrameIdentification: `${commonBase}/FaceCalibration/FaceCalibrationFrameIdentification.png`,
    FaceCalibrationScanComplete: `${commonBase}/FaceCalibration/FaceCalibrationScanComplete.png`,
    // images dropdown
    person: `${commonBase}/Logos/person.png`,
    MyAccount: `${commonBase}/Logos/MyAccount.svg`,
    AhuraMyAccount: `${commonBase}/Logos/AhuraMyAccount.svg`,
    uploadPicture: `${commonBase}/Logos/uploadPicture.png`,

    // ManagerDashboard

    headerManager: `${commonBase}/Logos/headerManager.svg`,

    sidebar: {
      Home: `${commonBase}/Sidebar/Home.svg`,
      Education: `${commonBase}/Sidebar/Education.svg`,
      Employees: `${commonBase}/Sidebar/Employees.svg`,
      Metrics: `${commonBase}/Sidebar/Metrics.svg`,
      SessionLog: `${commonBase}/Sidebar/SessionLog.svg`,
      Chat: `${commonBase}/Sidebar/Chat.svg`,
      Payments: `${commonBase}/Sidebar/Payments.svg`,
      Library: `${commonBase}/Sidebar/Library.svg`,
      License: `${commonBase}/Sidebar/License.svg`,
    },

    learningPlanIcon: `${commonBase}/LearningPlan/Route.svg`,
    setGoals: `${commonBase}/setGoals.png`,
    growthMindset: `${commonBase}/growthMindset.png`,
    watchTutorials: `${commonBase}/watchTutorials.jpg`,
    procrastinationChallenge: `${commonBase}/procrastinationChallenge.png`,
  },
  Videos: {
    FinalLoading: `${commonBase}/Gifs/Finalloading.gif`,
  },
  icons: {
    podcast: `${commonBase}/icons/podcast.svg`,
    headphones: `${commonBase}/icons/headphones.svg`,
    article: `${commonBase}/icons/article.svg`,
    courses: `${commonBase}/icons/courses.svg`,
    udemy: `${commonBase}/icons/Udemy.svg`,
    googleBlack: `${commonBase}/icons/googleBlack.svg`,
  },
  Course: {
    course: `${commonBase}/Course/course.png`,
  },
  info: {
    avatar: `${commonBase}/infoPage/avatar.png`,
  },
};
