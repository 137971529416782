/**
 * @module Course
 * renders /Course
 */

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import CourseData from '../../components/Course/CourseData';
import { fetchCourseDetails } from '../../helpers/Course/CourseDetails';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from '../../redux/Actions/Loader';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

const Course = () => {
  const [view, setView] = useState('course');
  const { id } = useParams();
  const [courseDetails, setCourseDetails] = useState([]);
  const [category, setCategory] = useState('course');
  const userData = useSelector((state) => state.generalInformation);
  const dispatch = useDispatch();
  const [campaignCategory, setCampaignCategory] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      window.scrollTo(0, 0);
      const res = await getCourseDetails(userData, id);
      setCampaignCategory(res?.campaign_category);
    }

    fetchData();
    // eslint-disable-next-line
  }, [userData]);

  const getCourseDetails = async (userData, id) => {
    dispatch(showLoader());
    const response = await fetchCourseDetails(userData?.id, id);
    if (response?.data && response?.data.length > 0) {
      console.log('Course Details Data', response.data);
      console.log('Course Detail', response.data[0]);
      setCourseDetails(response.data[0]);
      dispatch(hideLoader());
      return response?.data[0];
    } else {
      dispatch(hideLoader());
      setDialogOpen(true); // Open dialog if no course data is found
    }
  };

  const getLessonData = (userData, lesson_id) => {
    getCourseDetails(userData, lesson_id);
    setCategory('lesson');
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    history.push('/content'); // Redirect to Courses page
  };

  return (
    <React.Fragment>
      {view === 'course' && (
        <div className={classNames('w-100 bg-white p-4')}>
          {/* <PersonalPlaybackList /> */}
          <div className={classNames('px-4 pt-3')}>
            <CourseData
              courseDetails={courseDetails}
              getCourseDetails={getLessonData}
              category={category}
              campaignCategory={campaignCategory}
            />
          </div>
        </div>
      )}

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Content Not Found</DialogTitle>
        <DialogContent>
          <Typography>
            The content you are looking for is not available. You will be
            redirected to the Courses page.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Course;
