import React, { useEffect } from 'react';
import c from './signIn.module.scss';
import classNames from 'classnames';
import SignIn from '../../components/signIn';
import { SignInFooter } from '../../components/signIn/Footer';
import SignUpNav from '../../components/signUp/SignUpNav';
import { Redirect, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const SignInPage = () => {
  const location = useLocation();
  const token = localStorage.getItem('token');

  // Google Analytics tracking
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search + location.hash,
      title: location.pathname,
    });
  }, [location]);
  // Handle redirection logic if the user is already authenticated
  if (token) {
    return <Redirect to="/info" />;
  }

  return (
    <>
      <SignUpNav />
      <div className={classNames(c.signInPageContainer)}>
        <div className={classNames(c.signInPage)}>
          <SignIn />
          <SignInFooter />
        </div>
      </div>
    </>
  );
};

export default SignInPage;
