import React, { useState, useEffect, useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import SaveIcon from '@mui/icons-material/Save';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  Box,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import manageCourses from '../../manageCourses.module.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { updateCourse, createCourse } from '../../../../helpers/Content';
import {
  CLIENT_ID,
  MODULE_ID,
  CAMPAIGN_TYPES_IDS,
} from '../../../../config/Constants';
import { API_URLS } from '../../../../config/API_URLS/api_urls';
import { postApiCall } from '../../../../utils/axios/axios';

const CourseSyllabus = ({
  edit,
  setEdit,
  course: { course },
  handleCourseNameValueChange,
  categories,
  setCategories,
  setCourseToEdit,
}) => {
  const { client_name, client_id, user_id } = useSelector(
    (state) => state?.generalInformation
  );
  const corp_id = client_id;

  const [category, setCategory] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [courseName, setCourseName] = useState('');
  const [image_url, setImageURL] = useState('');
  const [description, setDescription] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [open, setOpen] = useState(false); // Modal open state
  const [error, setError] = useState({}); // Error state for validation
  const timeoutRef = useRef(null); // Ref to store the timeout ID
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (course && categories.length > 0) {
      const matchedCategory = categories.find(
        (cat) => cat.id === course.category_id
      );
      setCategory(matchedCategory || '');
      setCourseName(course.heading || '');
      setImageURL(course.cover_image_url || '');
      setDescription(course.description || '');
      setIsPublic(course.is_public === undefined ? true : course.is_public);
    }
  }, [categories, course]);

  // Handle changes to the course name input field
  const handleCourseNameChange = (e) => {
    const value = e.target.value;
    setCourseName(value);

    // Clear any existing timeout to prevent premature updates
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout to call handleCourseNameValueChange after typing stops
    timeoutRef.current = setTimeout(() => {
      handleCourseNameValueChange(value);
    }, 1000); // 1000ms delay
  };

  // Cleanup function to clear timeout when the component unmounts
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleToggleChange = () => {
    setIsPublic((prev) => !prev);
  };

  // Open the modal to add a new category
  const handleOpenModal = () => {
    setOpen(true);
  };

  // Close the modal and reset new category
  const handleCloseModal = () => {
    setOpen(false);
    setNewCategory('');
  };

  // Create the new category
  const createCategory = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Create the request body with the new category name
    const reqBody = {
      name: newCategory, // New category name
      description: description || '', // Optional, default to an empty string if not provided
      image_url: image_url || '', // Optional, default to an empty string if not provided
      crm_module_id: MODULE_ID.CAMPAIGN,
      campaign_type_id: CAMPAIGN_TYPES_IDS.courses,
      owner_id: corp_id,
      client_id: CLIENT_ID,
      created_by: CLIENT_ID,
      updated_by: CLIENT_ID,
    };

    try {
      // Prepare the parameters for the API call
      const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };

      // Make the API call to create the category
      const category_response = await postApiCall(
        API_URLS.create_capability, // Assuming this is the correct endpoint for creating a category
        reqBody,
        paramsData
      );

      // Handle the response
      console.log('Category API Response:', category_response);

      // Format the new category object from the response
      const newCategory = {
        id: category_response.data.id, // Assuming 'id' is the key for the category ID
        name: category_response.data.name, // Assuming 'name' is the key for the category name
      };

      // Append the new category to the existing categories
      setCategories((prevList) => [...prevList, newCategory]);

      // Reset the modal input values after a successful submission
      setNewCategory('');
      handleCloseModal(); // Close the modal after adding the category
    } catch (err) {
      // Handle any errors during the API call
      console.error('Error creating category:', err);
      throw new Error(err?.data?.error?.message);
    }
  };

  const handleSave = async () => {
    setButtonLoading(true);
    setError({}); // Reset errors on save attempt
    const courseData = {
      client_id: CLIENT_ID,
      category_id: category.id,
      campaign_type_id: category.campaign_type_id,
      heading: courseName,
      description,
      author_id: user_id,
      is_public: isPublic,
      corporate_id: corp_id,
      cover_image_url: image_url,
      image_url: image_url,
      created_by: user_id,
      updated_by: user_id,
    };

    // Initialize an object to collect errors
    const errors = {};

    // Validate fields
    if (!courseName) {
      errors.courseName = 'Course name is required.';
    }
    if (!category) {
      errors.category = 'Category is required.';
    }
    if (!image_url) {
      errors.image_url = 'Image URL is required.';
    }
    if (!description) {
      errors.description = 'Description is required.';
    }

    // If there are any errors, update the error state and stop processing
    if (Object.keys(errors).length > 0) {
      setError(errors);
      setButtonLoading(false);
      return;
    }

    try {
      if (edit) {
        courseData.id = course.id;
        const response = await updateCourse(course.id, courseData);
        if (response) {
          console.log('Course updated successfully:', response);
          setEdit(true);
          setCourseToEdit(response);
          setImageURL(response.cover_image_url);
        } else {
          console.error('Course update failed:', response.message);
        }
      } else {
        const response = await createCourse(courseData);
        if (response.error) {
          console.log('Course creation failed:', response.error);
          setDescription(description);
          setImageURL(image_url);
        } else if (response.id) {
          console.log('Course created successfully:', response);
          setEdit(true);
          setCourseToEdit(response);
          setImageURL(response.cover_image_url);
        }
      }
    } catch (error) {
      console.error('Error saving course:', error);
      setCourseToEdit(courseData);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <Box
      className={classNames(manageCourses.container, 'p-4')}
      sx={{ borderRadius: 2 }}
    >
      <Typography
        variant="h6"
        gutterBottom
        className={classNames(manageCourses.primaryColor, 'fw-bold')}
      >
        {edit ? 'Edit Course Syllabus' : 'Create Course Syllabus'}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="d-flex align-items-center mb-3">
            <FormControl
              variant="outlined"
              size="small"
              sx={{ marginRight: 1, minWidth: 200 }}
              error={!!error.category} // Add error styling
            >
              <InputLabel id="category-label">Select Category</InputLabel>
              <Select
                labelId="category-label"
                id="category-select"
                value={category}
                onChange={handleCategoryChange}
                label="Select Category"
              >
                {categories.map((cat) => (
                  <MenuItem key={cat.id} value={cat}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpenModal}
            >
              Add Category
            </Button>
          </div>
          {error.category && (
            <p style={{ color: 'red' }} className="error-text">
              {error.category}
            </p>
          )}{' '}
          {/* Display error message */}
        </Grid>

        <Grid item xs={12}>
          <TextField
            inputRef={inputRef}
            label="Course Name"
            variant="outlined"
            size="small"
            value={courseName}
            onChange={handleCourseNameChange} // Use the debounced input change handler
            sx={{ marginBottom: 2, minWidth: 200 }}
            error={!!error.courseName} // Add error styling
            helperText={error.courseName} // Display helper text for validation
          />
        </Grid>

        <Grid item xs={12}>

          {/* TODO integrate with S3 Bucket */}
          {/* <Typography variant="subtitle1" gutterBottom>
            Add Image
          </Typography>
          <Button
            variant="outlined"
            startIcon={<UploadIcon />}
            component="label"
          >
            Upload Image
            <input type="file" hidden />
          </Button> */}
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Embedded Image Link"
            variant="outlined"
            size="small"
            value={image_url}
            onChange={(e) => setImageURL(e.target.value)}
            sx={{ marginRight: 1, minWidth: 200 }}
            error={!!error.image_url} // Add error styling
            helperText={error.image_url} // Display helper text for validation
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Enter Description
          </Typography>
          <ReactQuill
            value={description}
            onChange={setDescription}
            theme="snow"
            style={{ minHeight: '100px', marginBottom: '16px' }}
          />
          {error.description && (
            <p style={{ color: 'red' }} className="error-text">
              {error.description}
            </p>
          )}{' '}
          {/* Display error message */}
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch checked={isPublic} onChange={handleToggleChange} />
            }
            label={isPublic ? 'Is Public' : 'Is Private'}
          />
        </Grid>
      </Grid>

      <Button
        variant="outlined"
        startIcon={<SaveIcon />}
        onClick={handleSave}
        disabled={buttonLoading}
      >
        {buttonLoading
          ? 'Saving...'
          : edit
          ? 'Update Course'
          : 'Save Course Syllabus'}
      </Button>

      {/* Modal for Adding Category */}
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={createCategory} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CourseSyllabus;
