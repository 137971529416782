import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Section from './Section';
import {
  createCourseSection,
  updateCourseSection,
  deleteCourseSection,
  createLesson,
  updateLesson,
  updateSortOrder,
} from '../../../../helpers/Content';
import { fetchLessonData } from '../../../../helpers/Course/CourseDetails';
import { useSelector } from 'react-redux';

const SectionAndLesson = React.memo(({ course: { course } }) => {
  const [sections, setSections] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [activeEditIndex, setActiveEditIndex] = useState(null);
  const [sectionDataTemplate, setSectionDataTemplate] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [sectionToDelete, setSectionToDelete] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [lessonLoading, setLessonLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const courseIdRef = useRef(course.id);
  const dragEndTimeout = useRef(null);

  const { user_id, client_id } = useSelector(
    (state) => state.generalInformation
  );

  const isFetchedRef = useRef(false);

  const fetchData = useCallback(() => {
    setIsFetching(true);
    fetchLessonData(courseIdRef.current)
      .then((data) => setSections(data || []))
      .catch((error) => console.error('Error fetching sections:', error))
      .finally(() => setIsFetching(false));
  }, []);

  useEffect(() => {
    if (!isFetchedRef.current && course.id) {
      fetchData();
      isFetchedRef.current = true;
    }
  }, [fetchData, course.id]);

  useEffect(() => {
    setSectionDataTemplate({
      parent_id: course.id,
      client_id,
      category_id: course.category_id,
      campaign_type_id: course.campaign_type_id,
      author_id: user_id,
      is_public: course.is_public,
      corporate_id: course.corporate_id,
      created_by: user_id,
      updated_by: user_id,
      heading: '',
      description: '',
      lessons: [],
    });
  }, [course, user_id, client_id]);

  const handleAddSection = () => {
    const newSection = { ...sectionDataTemplate, id: null };
    setSections((prev) => [...prev, newSection]);
    setActiveEditIndex(sections.length);
  };

  const handleSaveSection = async (sectionData, index) => {
    try {
      const payload = { ...sectionData, client_id };
      let savedSection;
      setButtonLoading(true);

      if (sectionData.id) {
        savedSection = await updateCourseSection(sectionData.id, payload);
      } else {
        savedSection = await createCourseSection(payload);
        sectionData = { ...sectionData, id: savedSection.id };
      }

      const updatedSection = { ...sectionData, ...savedSection };

      setSections((prev) => {
        const filteredSections = prev.filter((section) => section.id !== null);
        const sectionExists = filteredSections.some(
          (section) => section.id === updatedSection.id
        );

        return sectionExists
          ? filteredSections.map((section) =>
              section.id === updatedSection.id ? updatedSection : section
            )
          : [...filteredSections, updatedSection];
      });

      setButtonLoading(false);
    } catch (error) {
      console.error('Error saving section:', error);
      setButtonLoading(false);
    }
  };

  const handleCancelEdit = (index) => {
    const section = sections[index];
    if (!section.id) {
      setSections((prev) => prev.filter((_, idx) => idx !== index));
    }
    setActiveEditIndex(null);
  };

  const handleSaveLesson = async (lessonData, sectionId) => {
    try {
      let savedLesson;
      setLessonLoading(true);
      if (lessonData.id) {
        savedLesson = await updateLesson(lessonData.id, lessonData);
      } else {
        savedLesson = await createLesson({
          ...lessonData,
          parent_id: sectionId,
          client_id,
          category_id: course.category_id,
          campaign_type_id: course.campaign_type_id,
          author_id: course.author_id || user_id,
          is_public: course.is_public,
          corporate_id: course.corporate_id,
          created_by: course.created_by || user_id,
          updated_by: course.updated_by || user_id,
        });
        lessonData.id = savedLesson.id;
      }
      setLessonLoading(false);
      setSections((prev) =>
        prev.map((section) =>
          section.id === sectionId
            ? {
                ...section,
                lessons: section.lessons.some(
                  (lesson) => lesson.id === savedLesson.id
                )
                  ? section.lessons.map((lesson) =>
                      lesson.id === savedLesson.id ? savedLesson : lesson
                    )
                  : [...section.lessons, savedLesson],
              }
            : section
        )
      );
    } catch (error) {
      console.error('Error saving lesson:', error);
    }
  };

  const handleOpenDeleteDialog = (index) => {
    setSectionToDelete(index);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    const sectionId = sections[sectionToDelete]?.id;
    setDeleting(true);

    if (sectionId) {
      deleteCourseSection(sectionId)
        .then(() => {
          setSections((prev) =>
            prev.filter((_, idx) => idx !== sectionToDelete)
          );
        })
        .catch(console.error)
        .finally(() => {
          setTimeout(() => {
            setDeleting(false);
            setDeleteDialogOpen(false);
            setSectionToDelete(null);
          }, 100);
        });
    } else {
      setSections((prev) => prev.filter((_, idx) => idx !== sectionToDelete));
      setDeleting(false);
      setDeleteDialogOpen(false);
      setSectionToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setSectionToDelete(null);
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const reorderedSections = Array.from(sections);
    const [movedSection] = reorderedSections.splice(source.index, 1);
    reorderedSections.splice(destination.index, 0, movedSection);

    setSections(reorderedSections);

    // Clear any existing timeout and set a new one to debounce updateSortOrder call
    clearTimeout(dragEndTimeout.current);
    dragEndTimeout.current = setTimeout(() => {
      updateSortOrder(course.id, reorderedSections);
    }, 1000);
  };

  return (
    <Box>
      {isFetching || deleting ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '500px',
          }}
        >
          <CircularProgress size={150} color="primary" />
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="flex-start" gap={2} mb={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddSection}
            >
              Add Section
            </Button>
          </Box>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="sections">
              {(provided) => (
                <Box {...provided.droppableProps} ref={provided.innerRef}>
                  {sections.map((section, index) => (
                    <Draggable
                      key={index}
                      draggableId={`section-${index}`}
                      index={index}
                      isDragDisabled={activeEditIndex !== null}
                    >
                      {(provided) => (
                        <Section
                          sectionData={section}
                          sectionDataTemplate={sectionDataTemplate}
                          onSaveSection={(data) =>
                            handleSaveSection(data, index)
                          }
                          onDeleteSection={() => handleOpenDeleteDialog(index)}
                          onLessonSave={(lessonData) =>
                            handleSaveLesson(lessonData, section.id)
                          }
                          setSections={setSections}
                          isEditing={activeEditIndex === index}
                          onEdit={() => setActiveEditIndex(index)}
                          onCancelEdit={() => handleCancelEdit(index)}
                          buttonLoading={buttonLoading}
                          setButtonLoading={setButtonLoading}
                          lessonLoading={lessonLoading}
                          setLessonLoading={setLessonLoading}
                          provided={provided}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>

          <Dialog open={deleteDialogOpen} onClose={handleCancelDelete}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this section? This action cannot
                be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelDelete}>Cancel</Button>
              <Button color="error" onClick={handleConfirmDelete}>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
});

export default SectionAndLesson;
