import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { CheckCircle } from '@mui/icons-material';
import Headings from '../../../../Shared/Headings';
// import Text from '../../../../Shared/Text';
import classNames from 'classnames';
import ChapterProgressStyling from '../LearningProgress.module.scss';
// import { stepsData } from '../../../../../MockData/Course';
import { Resources } from '../../../../../config/Resources';
import { StepConnector } from '@mui/material';
import {
  fetchLessonData,
  fetchUserAssignmentDetails,
} from '../../../../../helpers/Course/CourseDetails';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function ChapterProgress({ getCourseDetails, ...props }) {
  const { id } = useParams();
  // const activeStep = 0
  const [lessonData, setLessonData] = React.useState([]);
  const userData = useSelector((state) => state?.generalInformation);
  const [userAssignmentData, setUserAssignmentData] = React.useState([]);

  React.useEffect(() => {
    getLessonData();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getAssignmentDetails();
  }, [userData]);

  // const getLessonData = async () => {
  //   const response = await fetchLessonData(id);
  //   if (response?.length) {
  //     response?.sort((sec1, sec2) => {
  //       return sec1?.seq_id - sec2?.seq_id;
  //     });
  //     response?.forEach((section) => {
  //       section?.lessons?.sort((l1, l2) => l1?.seq_id - l2?.seq_id);
  //     });
  //     setLessonData(response);
  //   }
  // };

  const getLessonData = async () => {
    const response = await fetchLessonData(id);
    if (response?.length) {
      response?.sort((sec1, sec2) => {
        return sec1?.sort_order - sec2?.sort_order;
      });
      response?.forEach((section) => {
        section?.lessons?.sort((l1, l2) => l1?.sort_order - l2?.sort_order);
      });
      setLessonData(response);
    }
  };

  const getAssignmentDetails = async () => {
    if (userData?.id) {
      const response = await fetchUserAssignmentDetails(userData?.id);
      if (response) setUserAssignmentData(response);
    }
  };

  const CustomStepIcon = (id) => {
    const stepIcons = {
      1: (
        <CheckCircle
          className={classNames(ChapterProgressStyling.StepperIcon)}
        />
      ),
      2: (
        <img
          src={Resources.images.OpenFolder}
          alt=""
          className={classNames(ChapterProgressStyling.StepperIcon)}
        />
      ),
    };

    return <>{!isCompletedGet(id) ? stepIcons[2] : stepIcons[1]}</>;
  };

  const isCompletedGet = (id) => {
    const isCompleted =
      userAssignmentData &&
      userAssignmentData.find(
        (completedLesson) => completedLesson.campaign_id === id
      );
    return isCompleted ? true : false;
  };

  const getLessonDetails = (id) => {
    getCourseDetails(userData, id);
  };

  return (
    <React.Fragment>
      {lessonData &&
        lessonData?.map((stepsData, index) => (
          <div key={index} className={classNames('mt-3')}>
            <Headings
              textType="h0"
              classToOverride={ChapterProgressStyling.textColor}
            >
              {stepsData?.heading}
            </Headings>
            {/* <Stepper className={ChapterProgressStyling.ChapterContainer} activeStep={activeStep} orientation="vertical"> */}
            <Stepper
              className={ChapterProgressStyling.ChapterContainer}
              activeStep={false}
              orientation="vertical"
            >
              {stepsData &&
                stepsData?.lessons?.map((step, index) => (
                  <Step
                    // disabled={step.isCompleted}
                    key={index}
                    onClick={() => getLessonDetails(step?.id)}
                  >
                    <StepLabel
                      connector={<StepConnector />}
                      StepIconComponent={() => CustomStepIcon(step?.id)}
                      className={classNames(
                        ChapterProgressStyling.cursorPointer
                      )}
                    >
                      <Headings
                        classToOverride={classNames(
                          !isCompletedGet(step?.id) && `fw-bold`
                        )}
                        headingType="h6"
                      >
                        {step?.heading}
                      </Headings>
                      {/* <div className={classNames(ChapterProgressStyling.descriptionText)} dangerouslySetInnerHTML={{__html: step?.description}}/> */}
                    </StepLabel>
                  </Step>
                ))}
            </Stepper>
          </div>
        ))}
    </React.Fragment>
  );
}
