import React, { useEffect, useState, useCallback } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import CourseSyllabus from './CourseSyllabus';
import SectionAndLesson from './SectionAndLesson';
import Assignments from './Assignments';

const CourseContent = ({
  edit,
  setEdit,
  course,
  onChangeCourseName,
  categories,
  setCategories,
  setCourseToEdit,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleChangeCourseName = useCallback((newValue) => {
    onChangeCourseName(newValue);
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const TabContent = ({ children }) => (
    <Box p={3}>
      <Typography>{children}</Typography>
    </Box>
  );

  return (
    <div>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="fullWidth"
        indicatorColor="CourseContentprimary"
        textColor="primary"
      >
        <Tab label="Course Syllabus" />
        <Tab label="Section & Lessons" />
        <Tab label="Assignments" />
        <Tab label="Analytics" />
      </Tabs>

      {activeTab === 0 && (
        <TabContent>
          <CourseSyllabus
            edit={edit}
            setEdit={setEdit}
            course={{ course: course }}
            handleCourseNameValueChange={handleChangeCourseName}
            categories={categories}
            setCategories={setCategories}
            setCourseToEdit={setCourseToEdit}
          />
        </TabContent>
      )}
      {activeTab === 1 && (
        <TabContent>
          <SectionAndLesson course={{ course: course }} />
        </TabContent>
      )}
      {activeTab === 2 && (
        <TabContent>
          <Assignments course={{ course: course }} />
        </TabContent>
      )}
      {activeTab === 3 && <TabContent>Analytics</TabContent>}
    </div>
  );
};

export default CourseContent;
