import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Headings from '../../components/Shared/Headings';
import Text from '../../components/Shared/Text';
import SignUpNav from '../../components/signUp/SignUpNav';
import c from './privacypolicy.module.scss';
import ReactGA from 'react-ga4';

const PrivacyPolicy = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search + location.hash,
      title: location.pathname,
    });
  }, [location]);

  return (
    <>
      <SignUpNav />
      <Text
        textType={'t0'}
        classToOverride={classNames('bg-white p-5', c.privacyPolicy)}
      >
        <Headings
          headingType={'h3'}
          classToOverride={classNames(
            c.primaryColor,
            'fw-bold text-center mb-3'
          )}
        >
          AHURA PRIVACY POLICY
        </Headings>
        <Headings headingType="h6" classToOverride={classNames('text-center')}>
          <span>Last Updated: </span> Nov 2024
        </Headings>
        <div className="mt-5">
          Welcome to Ahura! Ahura is a business-to-business (or “B2B”) corporate
          learning platform that provides employers and educators with software
          licenses that enables them to personalize the digital learning
          experiences they deliver. Ahura uses artificial intelligence to
          accompany learners on their learning journeys by providing
          personalized guidance on how to improve and speed up their time
          dedicated to learning.
        </div>
        <div className="mt-5">
          Ahura offers its services to both Individual Users (a user who creates
          an account using a personal email address) and Corporate Users (people
          whose employer or educator has entered into an agreement with Ahura to
          use our services). If you are an Corporate User, the agreements
          between Ahura and your employer, such as your employer’s employee
          privacy notice, supersede some practices outlined in this Privacy
          Policy.
        </div>
        <div className="mt-5">
          This Privacy Policy explains how Ahura (“Ahura,” “we,” or “us”)
          collects, uses, and discloses information about you, both as an
          individual user and a corporate user. This Privacy Policy applies when
          you use our product, websites, other online products and services
          (collectively, our “Services”), contact our customer service team,
          engage with us on social media, or otherwise interact with us.
        </div>
        <div className="mt-5">
          We may change this Privacy Policy from time to time. If we make
          changes, we will notify you by revising the date at the top of this
          policy and, in some cases, we may provide you with additional notice
          (such as adding a statement to our website or sending you a
          notification). We encourage you to review this Privacy Policy
          regularly to stay informed about our information practices and the
          choices available to you.
        </div>
        <Headings headingType={'h4'} classToOverride="fw-bold mt-5">
          CONTENTS
        </Headings>
        <ul className="mt-2">
          <li
            onClick={() => document.getElementById('coi')?.scrollIntoView()}
            className={classNames(c.cursorPointer, c.li)}
          >
            Collection of Information
          </li>
          <li
            className={classNames(c.cursorPointer, c.li)}
            onClick={() => document.getElementById('uoi')?.scrollIntoView()}
          >
            Use of Information
          </li>
          <li
            className={classNames(c.cursorPointer, c.li)}
            onClick={() => document.getElementById('soi')?.scrollIntoView()}
          >
            Sharing of Information
          </li>
          <li
            className={classNames(c.cursorPointer, c.li)}
            onClick={() =>
              document.getElementById('analytics')?.scrollIntoView()
            }
          >
            Analytics
          </li>
          <li
            className={classNames(c.cursorPointer, c.li)}
            onClick={() => document.getElementById('toi')?.scrollIntoView()}
          >
            Transfer of Information to the United States and Other Countries
          </li>
          <li
            className={classNames(c.cursorPointer, c.li)}
            onClick={() => document.getElementById('dntn')?.scrollIntoView()}
          >
            Do Not Track Notice
          </li>
          <li
            className={classNames(c.cursorPointer, c.li)}
            onClick={() => document.getElementById('child')?.scrollIntoView()}
          >
            Children
          </li>
          <li
            className={classNames(c.cursorPointer, c.li)}
            onClick={() => document.getElementById('yc')?.scrollIntoView()}
          >
            Your Choices
          </li>
          <li
            className={classNames(c.cursorPointer, c.li)}
            onClick={() => document.getElementById('contact')?.scrollIntoView()}
          >
            Contact Us
          </li>
        </ul>
        <Headings headingType={'h4'} classToOverride="fw-bold mt-5">
          <span id="coi">Collection of Information</span>
        </Headings>
        <Headings headingType={'h5'} classToOverride="fw-bold mt-4 mb-2">
          Information You Provide to Us
        </Headings>
        We collect information you provide directly to us. For example, you
        share information directly with us when you create an account, fill out
        a form, use our Services (for example, audio, video, and images), make a
        purchase from or donation towards Ahura, communicate with us via
        third-party platforms, request customer support, or otherwise
        communicate with us. The types of personal information we may collect
        include your name, email address, postal address, phone number, profile
        image, credit card and other payment information, and any other
        information you choose to provide including employment and professional
        information as provided through any applications.
        <Headings headingType={'h4'} classToOverride="fw-bold mt-4 mb-2">
          Information We Collect Automatically When You Interact with Us
        </Headings>
        When you access or use our Services or otherwise transact business with
        us, we automatically collect certain information, including:
        <ul className="mt-2">
          <li>
            <b>Learning Activity Information:</b> In order to provide insights
            and recommendations that personalize and improve your learning
            experience, our AI analyzes your video and browsing history once you
            initiate the learning session, and stops this analysis once you
            pause or stop the learning session. For Pilot or Early Access users
            and users who opt-in, especially in regions where we haven’t trained
            our AI for the nuances of learning in that region, we temporarily
            save the learning video data to use for training the AI to better
            suit new learning cohorts.
          </li>
          <li>
            <b>Device and Usage Information:</b> We collect information about
            how you access our Services, including data about the device and
            network you use, such as your hardware model, operating system
            version, mobile network, IP address, unique device identifiers,
            browser type, and app version. We also collect information about
            your activity on our Services, such as access times, pages viewed,
            links clicked, and the page you visited before navigating to our
            Services.
          </li>
          <li>
            <b>Location Information:</b> In accordance with your device
            permissions, we may collect information about the precise location
            of your device. You may stop the collection of precise location
            information at any time (see the Your Choices section below for
            details).
          </li>
          <li>
            <b>
              Information Collected by Cookies and Similar Tracking
              Technologies:
            </b>
            We (and our service providers) use tracking technologies, such as
            cookies and web beacons, to collect information about you. Cookies
            are small data files stored on your hard drive or in device memory
            that help us improve our Services and your experience, see which
            areas and features of our Services are popular, and count visits.
            Web beacons (also known as “pixel tags” or “clear GIFs”) are
            electronic images that we use on our Services and in our emails to
            help deliver cookies, count visits, and understand usage and
            campaign effectiveness. For more information about cookies and how
            to disable them, see the Your Choices section below.
          </li>
        </ul>
        <Headings headingType={'h4'} classToOverride="fw-bold mt-5 mb-2">
          Information We Collect from Other Sources
        </Headings>
        We obtain information from third-party sources. For example, we may
        collect information about you from data analytics providers and others.
        Additionally, if you create or log into your Ahura account through a
        third-party platform (such as LinkedIn, Facebook, or Google), we will
        have access to certain information from that platform, such as your
        name, birthday, and profile picture, in accordance with the
        authorization procedures determined by such platform.
        <Headings headingType={'h4'} classToOverride="fw-bold mt-5 mb-2">
          Information We Derive
        </Headings>
        We may derive information or draw inferences about you based on the
        information we collect. For example, we may make inferences about your
        location based on your IP address and based on your browsing behavior.
        Our artificial intelligence also uses the audio and video captured to
        make certain inferences regarding your learning behavior in order to
        inform recommendations that improve your learning experience and
        personalize your education.
        <Headings headingType={'h4'} classToOverride="fw-bold mt-5 mb-2">
          <span id="uoi">USE OF INFORMATION</span>
        </Headings>
        We collect and use personal information solely for the purpose of
        providing educational services and improving the learning experience. We
        do not sell or share personal information with third-party advertisers
        or marketers:
        <ul className="mt-3">
          <li>
            Personalize and improve your learning experience on our Services
            e.g. by creating a personalized education;
          </li>
          <li>Provide, maintain, and improve our products and services;</li>
          <li>
            Process transactions and send you related information, including
            confirmations, receipts, offers, and customer experience surveys;
          </li>
          <li>
            Send you technical notices, security alerts, and support and
            administrative messages;
          </li>
          <li>
            Respond to your comments and questions and provide customer service;
          </li>
          <li>
            Communicate with you about products, services, and events offered by
            Ahura and others and provide news and information that we think will
            interest you (see the Your Choices section below for information
            about how to opt out of these communications at any time);
          </li>
          <li>
            Personalize the advertisements you see on third-party platforms and
            websites (for more information, see the Advertising and Analytics
            section below);
          </li>
          <li>
            Personalize the advertisements you see when you use our Services
            based on information provided by our advertising partners;
          </li>
          <li>
            Monitor and analyze trends, usage, and activities in connection with
            our Services;
          </li>
          <li>
            Detect, investigate, and prevent security incidents and other
            malicious, deceptive, fraudulent, or illegal activity and protect
            the rights and property of Ahura and others;
          </li>
          <li>Debug to identify and repair errors in our Services;</li>
          <li>Comply with our legal and financial obligations; and</li>
          <li>
            Carry out any other purpose described to you at the time the
            information was collected.
          </li>
        </ul>
        <Headings classToOverride={'mt-5 mb-2 fw-bold'} headingType="h4">
          <span id="soi">SHARING OF INFORMATION</span>
        </Headings>
        We share personal information in the following circumstances or as
        otherwise described in this policy:
        <ul className="mt-4">
          <li>
            {' '}
            We may share personal information with vendors, service providers,
            and consultants that need access to personal information in order to
            perform services for us, such as companies that assist us with web
            hosting, data analytics, shipping and delivery, payment processing,
            fraud prevention, customer service, and marketing and advertising.
          </li>
          <li>
            We may share personal information with customers to assist them with
            enhancing educational products.{' '}
          </li>
          <li>
            We may disclose personal information if we believe that disclosure
            is in accordance with, or required by, any applicable law or legal
            process, including lawful requests by public authorities to meet
            national security or law enforcement requirements.
          </li>
          <li>
            We may share personal information if we believe that your actions
            are inconsistent with our user agreements or policies, if we believe
            that you have violated the law, or if we believe it is necessary to
            protect the rights, property, and safety of Ahura, our users, the
            public, or others.
          </li>
          <li>
            We share personal information with our lawyers and other
            professional advisors where necessary to obtain advice or otherwise
            protect and manage our business interests.
          </li>
          <li>
            We may share personal information in connection with, or during
            negotiations concerning, any merger, sale of company assets,
            financing, or acquisition of all or a portion of our business by
            another company.{' '}
          </li>
          <li>
            We share personal information with your consent or at your
            direction.{' '}
          </li>
        </ul>
        We may also share aggregated or de-identified information that cannot
        reasonably be used to identify you.
        <Headings headingType={'h4'} classToOverride="fw-bold mt-5 mb-2">
          STORING AND SECURING INFORMATION WE COLLECT
        </Headings>
        <ul>
          <li>
            <p>
              <strong>Information Storage and Security:</strong> We use
              industry-standard technical and organizational measures to secure
              the information we store. While we implement safeguards designed
              to protect your information, no security system is impenetrable,
              and due to the inherent nature of the Internet, we cannot
              guarantee that information, during transmission through the
              Internet or while stored on our systems or otherwise in our care,
              is absolutely safe from intrusion by others. If you use our server
              or data center Services, responsibility for securing storage and
              access to the information you put into the Services rests with you
              and not Ahura. We strongly recommend that server or data center
              users configure SSL to prevent interception of information
              transmitted over networks and to restrict access to the databases
              and other storage points used.
            </p>
          </li>
          <li>
            <p>
              <strong>How Long We Keep Information:</strong> We retain personal
              information for as long as necessary to fulfill the purposes for
              which it was collected, comply with legal obligations, resolve
              disputes, and enforce our agreements. We will delete or
              de-identify personal information when it is no longer needed.
            </p>
            <p>Here are our general guidelines for data retention periods:</p>
            <ul>
              <li>
                <strong>Account Information:</strong> We retain your account
                information for <strong>4 years</strong> after your account is
                deactivated or deleted.
              </li>
              <li>
                <strong>Learning Activity Data:</strong> We retain your learning
                activity data, such as video and browsing history, for{' '}
                <strong>5 years</strong> to improve our services and personalize
                your learning experience.
              </li>
              <li>
                <strong>Payment Information:</strong> We retain payment
                information, such as credit card numbers, for{' '}
                <strong>2 years</strong> to comply with financial regulations
                and for fraud prevention purposes.
              </li>
              <li>
                <strong>Customer Support Records:</strong> We retain customer
                support records, including emails and chat logs, for{' '}
                <strong>3 years</strong> to assist with future inquiries and
                troubleshooting.
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Account Information:</strong> We retain your account
              information for as long as your account is active and for a
              reasonable period thereafter in case you decide to re-activate the
              Services. We also retain some of your information as necessary to
              comply with our legal obligations, resolve disputes, enforce our
              agreements, support business operations, and continue to develop
              and improve our Services. Where we retain information for Service
              improvement and development, we take steps to eliminate
              information that directly identifies you, and we only use the
              information to uncover collective insights about the use of our
              Services, not to specifically analyze personal characteristics
              about you.
            </p>
          </li>
          <li>
            <p>
              <strong>Information You Share on the Services:</strong> If your
              account is deactivated or disabled, some of your information and
              the content you have provided will remain in order to allow your
              team members or other users to make full use of the Services. For
              example, we continue to display messages you sent to the users
              that received them and continue to display content you provided,
              but when requested, details that can identify you will be removed.
            </p>
          </li>
          <li>
            <p>
              <strong>Managed Accounts:</strong> If the Services are made
              available to you through an organization (e.g., your employer), we
              retain your information as long as required by the administrator
              of your account. For more information, see "Managed accounts and
              administrators" above.
            </p>
          </li>
          <li>
            <p>
              <strong>Data Security and Incident Response:</strong> In the event
              of a data breach, we have a comprehensive incident response plan
              in place to promptly investigate, mitigate, and remediate the
              breach. We will notify affected individuals and regulatory
              authorities as required by law.
            </p>
          </li>
        </ul>
        <Headings headingType={'h4'} classToOverride="fw-bold mt-5 mb-2">
          <span id="yc">YOUR CHOICES AND CONTROL OVER YOUR INFORMATION</span>
        </Headings>
        <Headings headingType={'h6'} classToOverride="fw-bold mb-2">
          Account Information
        </Headings>
        <p>
          {' '}
          You may update and correct certain account information at any time by
          logging into your account and emailing us at <b>hi@Ahuraai.com</b>.
        </p>
        <p>
          If you wish to delete your account, please email us at{' '}
          <b>hi@Ahuraai.com</b>, but note that we may retain certain information
          as required by law or for our legitimate business purposes.
        </p>
        <p>
          Parents or guardians have the right to review their child's personal
          information, request corrections, and request deletion of their
          child's data. To exercise these rights, please contact us at
          hi@ahuraai.com.
        </p>
        <Headings headingType={'h6'} classToOverride="fw-bold mt-3 mb-1">
          Communications Preferences
        </Headings>
        You may opt out of receiving promotional emails from Ahura by following
        the instructions in those communications or by contacting us directly at
        the email provided below. If you opt out, we may still send you
        non-promotional emails, such as those about your account or our ongoing
        business relations.
        <Headings classToOverride="fw-bold mt-3 mb-1" headingType={'h6'}>
          Cookies
        </Headings>
        Most web browsers are set to accept cookies by default. If you prefer,
        you can usually adjust your browser settings to remove or reject browser
        cookies. Please note that removing or rejecting cookies could affect the
        availability and functionality of our Services.
        <Headings classToOverride={'fw-bold mt-3 mb-1'} headingType="h6">
          OTHER IMPORTANT PRIVACY INFORMATION
        </Headings>
        <b>
          <u>Protected information</u>
        </b>
        <br />
        These are a class of derived information saved about the user’s learning
        behavior purely to inform them to improve their learning habits, and are
        not shared with their administrators in non-aggregate forms. However,
        administrators that are designated as coaches and teachers can be
        provided this information to provide direct guidance to the user to
        improve their learning habits. This information class includes emotion
        trends, facial movement trends, browsing trends
        <div className="mt-3"></div>
        <b>
          <u>Notice to End Users</u>
        </b>
        <br />
        Many of our products are intended for use by organizations. Where the
        Services are made available to you through an organization (e.g. your
        employer), that organization is the administrator of the Services and is
        responsible for the accounts and/or Service sites over which it has
        control. If this is the case, please direct your data privacy questions
        to your administrator, as your use of the Services is subject to that
        organization's policies. We are not responsible for the privacy or
        security practices of an administrator's organization, which may be
        different than this policy.
        <div className="mt-3">Administrators are able to:</div>
        <ul>
          <li> require you to reset your account password;</li>
          <li>restrict, suspend or terminate your access to the Services;</li>
          <li>
            access to non-protected information in and about your account;
          </li>
          <li>access or retain information stored as part of your account;</li>
          <li>install or uninstall third-party apps or other integrations </li>
        </ul>
        <div className="mt-3">In some cases, administrators can also:</div>
        <ul>
          <li> restrict, suspend or terminate your account access;</li>
          <li>change the email address associated with your account;</li>
          <li>change your information, including profile information;</li>
          <li>
            restrict your ability to edit, restrict, modify or delete
            information
          </li>
        </ul>
        <div className="mt-3">
          Even if the Services are not currently administered to you by an
          organization, or if you use an email address provided by an
          organization (such as your work email address) to access the Services,
          then the owner of the domain associated with your email address (e.g.
          your employer) may assert administrative control over your account and
          use of the Services at a later date. You will be notified if this
          happens.
        </div>
        <div className="mt-3">
          If you do not want an administrator to be able to assert control over
          your account or use of the Services, you should deactivate your
          membership through your corporation, or use your personal email
          address to register for or access the Services. If an administrator
          has not already asserted control over your account or access to the
          Services, you can update the email address associated with your
          account through your account settings in your profile. Once an
          administrator asserts control over your account or use of the
          Services, you will no longer be able to change the email address
          associated with your account without administrator approval.
        </div>
        <div className="mt-3">
          Please contact your organization or refer to your administrator’s
          organizational policies for more information.
        </div>
        <div className="mt-3">
          <u>California Requirements</u>
          <ul className="mt-3">
            <li>
              {' '}
              <b>Exercising your rights:</b> If you are a California resident,
              there are some additional rights that may be available to you
              under the California Consumer Protection Act (“CCPA”). This policy
              explains the tools that we have made available to you to exercise
              your data rights under the CCPA, such as the right to deletion and
              the right to request access to the categories of information we
              have collected about you. For more information on how to exercise
              your rights please visit the “How to access and control your
              information” section of this policy. We encourage you to manage
              your information, and to make use of the privacy controls we have
              included in our Services. You will not be discriminated against
              for exercising any of your privacy rights under the CCPA. In order
              to protect your information from unauthorized access or deletion,
              we may require you to provide additional information for
              verification. If we cannot verify your identity, we will not
              provide or delete your information.
            </li>
            <li>
              <b>Sharing your personal information:</b> We don't sell your
              personal information. We do share your information with others as
              described in the “How we share information we collect” section of
              this policy. We also show ads that we think are relevant. We've
              provided more information about how you can manage your
              advertising, and do-not-track preferences, within this policy.
            </li>
            <li>
              <b>Processing your information:</b> This policy describes the
              categories of personal information we may collect, the sources of
              that information, and our deletion and retention policies. We’ve
              also included information about how we may process your
              information, which includes for "business purposes" under the CCPA
              - such as to protect against illegal activities, and for the
              development of new products, features, and technologies. If you
              have questions about the categories of information we may collect
              about you, please be sure to visit the section of this policy
              called, “What information we collect about you.” For more details
              about our processing activities, please be sure to visit the
              section called, “How we use information we collect.”{' '}
            </li>
          </ul>
        </div>
        <div className="mt-2">
          If you have any questions or would like to exercise your rights under
          the CCPA, you can reach out to us at legal@ahuraai.com.
        </div>
        <Headings headingType={'h4'} classToOverride="fw-bold mt-5 mb-2">
          <span id="analytics"> [ADVERTISING AND] ANALYTICS</span>
        </Headings>
        We allow others to provide analytics services [and serve advertisements]
        on our behalf across the web and in mobile apps. These entities may use
        cookies, web beacons, device identifiers, and other technologies to
        collect information about your use of our Services and other websites
        and applications, including your IP address, web browser, mobile network
        information, pages viewed, time spent on pages or in mobile apps, links
        clicked, and conversion information. This information may be used by
        Ahura and others to, among other things, analyze and track data,
        determine the popularity of certain content, [deliver advertising and
        content targeted to your interests on our Services and other websites],
        and better understand your online activity. [For more information about
        interest-based ads, or to opt out of having your web browsing
        information used for behavioral advertising purposes, please visit
        www.aboutads.info/choices [or change your settings using the consent
        management tool on our Services.]] [Your device may also include a
        feature (“Limit Ad Tracking” on iOS or “Opt Out of Interest-Based Ads”
        or “Opt Out of Ads Personalization” on Android) that allows you to opt
        out of having certain information collected through mobile apps used for
        behavioral advertising purposes.]
        <div className="mt-3">
          We also work with third parties to serve ads to you as part of
          customized campaigns on third-party platforms (such as Facebook and
          Instagram). As part of these ad campaigns, we or the third-party
          platforms may convert information about you, such as your email
          address and phone number, into a unique value that can be matched with
          a user account on these platforms to allow us to learn about your
          interests and serve you advertising that is customized to your
          interests. Note that the third-party platforms may offer you choices
          about whether you see these types of customized ads.
        </div>
        <Headings headingType={'h4'} classToOverride="fw-bold mt-5 mb-2">
          <span id="toi">
            TRANSFER OF INFORMATION TO THE UNITED STATES AND OTHER COUNTRIES
          </span>
        </Headings>
        Ahura is based in the United States, and we have operations and service
        providers in the United States and other countries. Therefore, we and
        our service providers may transfer your personal information to, or
        store or access it in, jurisdictions that may not provide levels of data
        protection that are equivalent to those of your home jurisdiction.
        <Headings headingType={'h4'} classToOverride="fw-bold mt-5 mb-2">
          <span id="dntn"> DO NOT TRACK NOTICE</span>
        </Headings>
        This website does not currently support certain browser settings or
        otherwise respond to Do Not Track requests.
        <Headings headingType={'h4'} classToOverride="fw-bold mt-5 mb-2">
          <span id="child">CHILDREN</span>
        </Headings>
        This website is not intended for or directed at children under the age
        of 13. In addition, we do not knowingly collect personal information
        from children under the age of 13.
        <Headings headingType={'h4'} classToOverride="fw-bold mt-5 mb-2">
          <span id="contact">CONTACT US</span>
        </Headings>
        If you have any questions about this Privacy Policy, please contact us
        at hi@Ahuraai.com .<div className="mb-5"></div>
      </Text>
    </>
  );
};

export default PrivacyPolicy;
