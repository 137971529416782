import { Resources } from '../../../config/Resources';
import { getApiCall } from '../../../utils/axios/axios';
import { API_URLS } from '../../../config/API_URLS/api_urls';
export const TopLearnersData = [
  {
    LearnerImage: Resources.images.home.TopLearners1,
    LearnerName: 'Catherine O’Hara',
    Capabilities: '5',
    Time: ' 6hr 33min',
  },
  {
    LearnerImage: Resources.images.home.TopLearners2,
    LearnerName: 'Akita Manning',
    Capabilities: '5',
    Time: '6hr 33min',
  },
  {
    LearnerImage: Resources.images.home.TopLearners3,
    LearnerName: 'Joseph Brigado',
    Capabilities: '5',
    Time: '6hr 33min',
  },
];

export const columns = [
  { id: 'learner', label: 'Learner', minWidth: 190 },
  { id: 'Monthly', label: 'Monthly Avg.', minWidth: 190 },
  {
    id: 'employee',
    label: 'Employee Details',
  },
];

export const menuItems = [
  { label: 'Learner', value: 'Learner' },
  { label: 'Monthly Avg.', value: 'Monthly Avg.' },
  { label: 'Employee Details', value: 'Employee Details' },
];

export const TrendingCoursesData = [
  {
    CourseImage: Resources.images.home.community1,
    CourseHeading: 'Back-End Coding',
    CourseViews: '122,000',
    LastMonthViews: '113,796',
    Margin: '+13%',
  },
  {
    CourseImage: Resources.images.home.community1,

    // CourseImage: Resources.images.home.community2,
    CourseHeading: 'Front-End Coding',
    CourseViews: '78,000',
    LastMonthViews: '65,000',
    Margin: '+8%',
  },

  {
    CourseImage: Resources.images.home.community3,
    CourseHeading: 'Management',
    CourseViews: '63,000',
    LastMonthViews: '51,796',
    Margin: '+4%',
  },
];

export const CommunitiesDropdown = [
  {
    label: 'All time',
    value: 'all',
  },
  {
    label: 'This Month',
    value: 'this',
  },
  {
    label: 'Last Month',
    value: 'prev',
  },
];

export const TrendingFilters = [
  { label: 'Capability', value: 'capability' },
  { label: 'Learning Plans', value: 'learning_plan' },
];

export const getCommunityData = async (user_id, filter, topic, corpID, platform_id) => {
  const params = {
    user_id: user_id,
    filter: filter,
    topic: topic,
  };
  if (corpID) {
    params.corp_id = corpID;
  }
 if (platform_id) {
    params.platform_id = platform_id;
  }
  const paramsData = { queryParams: params, headers: {}, isAuthorized: true };
  const response = await getApiCall(API_URLS.community, paramsData);
  return response?.data;
};
