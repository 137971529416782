import { API_URLS } from '../../config/API_URLS/api_urls';
import { CLIENT_ID } from '../../config/Constants';
import {
  getApiCall,
  postApiCall,
  deleteApiCall,
  putApiCall,
} from '../../utils/axios/axios';

export const getAllCourses = async (search = null) => {
  try {
    const params = {
      client_id: CLIENT_ID,
      search: search,
    };
    const paramsData = { queryParams: params, headers: {}, isAuthorized: true };
    const response = await getApiCall(
      API_URLS.courses_by_category_id,
      paramsData
    );
    return response.sort((a, b) => a.is_public - b.is_public);
  } catch (err) {
    console.log('Error in getAllCourses | helpers Content', err);
  }
};
export const getAllCategories = async () => {
  try {
    const params = {
      client_id: CLIENT_ID,
    };
    const paramsData = { queryParams: params, headers: {}, isAuthorized: true };
    const response = await getApiCall(API_URLS.categories, paramsData);
    return response;
  } catch (err) {
    console.log('Error in getAllCourses1 | helpers Content', err);
  }
};

// Function to create a new course
export const createCourse = async (courseData) => {
  const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
  const response = await postApiCall(
    API_URLS.createCourse,
    courseData,
    paramsData
  );
  return response;
};

// Function to update a course
export const updateCourse = async (courseId, courseData) => {
  const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
  const response = await putApiCall(
    `${API_URLS.updateCourse}/${courseId}`, // Include the course ID in the URL
    courseData,
    paramsData
  );
  return response;
};

// Function to delete an assigned course
export const deleteCourse = async (courseId) => {
  const queryParams = {};
  const paramsData = { queryParams, headers: {}, isAuthorized: true };
  const response = await deleteApiCall(
    API_URLS.deleteCourse + '/' + courseId,
    paramsData
  );
  return response;
};

// Function to create a new course section
export const createCourseSection = async (sectionData) => {
  const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
  const response = await postApiCall(
    API_URLS.createCourseSection,
    sectionData,
    paramsData
  );
  return response;
};

// Function to update an existing course section
export const updateCourseSection = async (sectionId, sectionData) => {
  const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
  const response = await putApiCall(
    `${API_URLS.updateCourseSection}/${sectionId}`,
    sectionData,
    paramsData
  );
  return response;
};
// Function to delete an assigned course section
export const deleteCourseSection = async (sectionId) => {
  const queryParams = {};
  const paramsData = { queryParams, headers: {}, isAuthorized: true };
  const response = await deleteApiCall(
    API_URLS.deleteCourseSection + '/' + sectionId,
    paramsData
  );
  return response;
};

// Function to create a new lesson
export const createLesson = async (lessonData) => {
  const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
  const response = await postApiCall(
    API_URLS.createLesson,
    lessonData,
    paramsData
  );
  return response;
};

// Function to update an existing lesson
export const updateLesson = async (lessonId, lessonData) => {
  const paramsData = { queryParams: {}, headers: {}, isAuthorized: true };
  const response = await putApiCall(
    `${API_URLS.updateLesson}/${lessonId}`, // Include the lesson ID in the URL
    lessonData,
    paramsData
  );
  return response;
};

// Function to delete an assigned lesson
export const deleteLesson = async (lessonId) => {
  const queryParams = {};
  const paramsData = { queryParams, headers: {}, isAuthorized: true };
  const response = await deleteApiCall(
    `${API_URLS.deleteLesson}/${lessonId}`, // Include the lesson ID in the URL
    paramsData
  );
  return response;
};

// Function to update the sort order of lessons or sections under a specific parent
export const updateSortOrder = async (parentId, items) => {
  // Prepare the payload to send with the sort order data
  const payload = { items };

  // Set up parameters for the API call
  const queryParams = {};
  const paramsData = { queryParams, headers: {}, isAuthorized: true };

  // Make the PUT request to update the sort order
  const response = await putApiCall(
    `${API_URLS.update_sort_order}/${parentId}`, // Include the parentId in the URL
    payload, // Pass the items as the request body
    paramsData
  );

  return response;
};
