import { getApiCall } from '../../utils/axios/axios';

export const API_URLS = {
  baseURL: process.env.REACT_APP_BASE_API_URL,
  login: '/v1/users/login',
  auth: '/v1/users/auth_user',
  updateUser: '/v1/users',
  mediaSignedURL: '/v1/media/signed_url',
  sign_up: '/v1/users/sign_up',
  save_user_interet: '/v1/saveUserInteret',
  LinkedIn: '/auth/linkedin',
  Facebook: '/auth/facebook',
  Google: '/auth/google',
  Get_Clients_Summary: '/v1/getClients',
  resend_confirmation: '/v1/users/resend_confirmation',
  activation_code_verification: '/v1/users/activation_code_verification',
  users: '/v1/users',
  campaign_by_id: '/v1/campaign_by_id',
  all_parent_campaigns: '/v1/all_parent_campaigns',
  create_capability: '/v1/create-category',
  categories: '/v1/categories',
  campaigns: '/v1/campaigns',
  campaigns_livefeed: '/v1/campaigns/livefeed',
  profile_details: '/v1/profile_details',
  getJoinedNeighborhood: '/v1/user/getNeighborhoodMainAccount',
  learning_plans: '/v1/learning_plans',
  learning: '/v1/learning',
  courses_by_category_id: '/v1/courses_by_category_id',
  campaign_details: '/v1/campaign_details',
  target_capabilities: '/v1/target_capabilities',
  // user_assignment_details: '/v1/userAssignmentDetails',
  highlights: '/v1/highlights',
  community: '/v1/community',
  accounts: '/v1/users/accounts',
  switch_account: '/v1/users/switch_account',
  expertise_journey: '/expertise-journey',
  user_email_check: '/v1/users/checkUserEmail',
  forgot_password: '/v1/users/forgot_password',
  create_password: '/v1/users/create_password',
  corp_highlights: '/v1/corp_highlights',
  all_corp_employees: '/v1/all_corp_employees',
  license_distribution: '/v1/license_distribution',
  // assignment or quiz
  add_quiz: '/v1/createLessonQuiz',
  get_quiz_by_lesson: '/v1/getQuizByLesson',
  update_quiz: '/v1/updateLessonQuiz',
  delete_quiz: '/v1/deleteLessonQuiz',
  assignment_question_for_admin: '/v1/assignmentQuestionForAdmin',
  assignment_question: '/v1/assignmentQuestion',
  user_assignment: '/v1/userAssignment',
  user_assignment_details: '/v1/userAssignmentDetails',
  add_question: '/v1/create/assignments/',
  update_question: '/v1/update/questions/',
  delete_question: '/v1/delete/questions/',
  delete_question_option: '/v1/delete/question-option/',
  assignment_sort: '/v1/assignments/sort/',
  question_sort: '/v1/questions/sort/',
  // assignment or quiz
  addUserToNeighborhood: '/v1/users/addUserToNeighborhood',
  invite: '/v1/invite_multiple',
  submit_quiz: '/v1/submitQuiz',
  user_assignment_report: '/v1/userAssignmentReport',
  allocation_table: '/v1/users/getNeighborhoodAllocation',
  set_neighborhood_admin: '/v1/users/setNeighborhoodadmin',
  update_save_video: '/v1/updateSaveVideo',
  updateNeighborhoodActiveStatus: '/v1/users/updateNeighborhoodActiveStatus',
  get_countries: '/v1/countries',
  get_state: '/v1/states',
  neighborhood_sign_up: '/v1/clients/neighborhood_sign_up',
  neighborhood_resend_confirmation: '/v1/clients/resend_confirmation',
  neighborhood_verify_activation_code: '/v1/clients/verify_activation_code',
  neighborhood_client: '/v1/clients',
  update_password: '/v1/reset_password',
  top_and_low_score_users: '/v1/topAndLowScore',
  capability_stats: '/v1/capability_stats',
  allEmployeeCapability: '/v1/employees_capabilities',
  getWeekSession: '/v1/get_weekly_session',
  getExpertiseJourneyData: '/v1/expertiseJourneyData',
  networkUsers: '/v1/networkUsers',
  nudge_tips: '/get_nudge_tips',
  deactivateMyAccount: '/v1/users/deactivate_my_account',
  makeMainAccount: '/v1/users/updateNeighborhoodMainAccount',
  removeUserFromNeighborhood: '/v1/users/removeUserFromNeighborhood',
  joinedGroups: '/v1/client/joined_groups',
  allGroups: '/v1/client/all_groups',
  membersData: '/v1/users/getNeighborhoodMembers',
  userClients: '/v1/user_clients/',
  notifications: '/notifications',
  updateNotifications: '/v1/user_clients_notifications/mark_as_read',
  acceptNotificationRequest: '/v1/users/updateNeighborhoodActiveStatus',
  bulkApproveMemberRequests: '/v1/bulkUpdateGroupStatus',
  organizationAssignedCapabilities: '/v1/organization-assigned-capabilities',
  allAssignedCapabilites: '/v1/assigned-capabilities',
  userAssignedCapability: '/v1/user-assigned-capabilities',
  createAssignedCapability: '/v1/create-assigned-capability',
  bulkCreateAssignedCapability: '/v1/bulk-create-assigned-capabilities',
  deleteAssignedCapability: '/v1/delete-assigned-capability',
  bulkDeleteAssignedCapability: '/v1/bulk-delete-assigned-capabilities',
  updateAssignedCapability: '/v1/update-assigned-capability',
  send_reminder: '/v1/send-assigned-capabilities-reminder',
  createCourse: '/v1/create-course',
  updateCourse: '/v1/update-course',
  deleteCourse: '/v1/delete-course',
  createCourseSection: '/v1/create-section-of-course',
  updateCourseSection: '/v1/update-section-of-course',
  deleteCourseSection: '/v1/delete-section-of-course',
  createLesson: '/v1/create-lesson-of-section',
  updateLesson: '/v1/update-lesson-of-section',
  deleteLesson: '/v1/delete-lesson-of-section',
  update_sort_order: '/v1/campaigns/sort',
  subscribe_field_focus_weekly_subscription:
    '/v1/subscribe/field_focus_weekly_subscription',
  unsubscribe_field_focus_weekly_subscription:
    '/v1/unsubscribe/field_focus_weekly_subscription',
};

export const CHROME_EXTENSION_API = `${process.env.REACT_APP_BASE_EXT_API_URL}/sessions_user/`;
export const ALL_USER_SESSIONS = (ext_id) =>
  `${process.env.REACT_APP_BASE_EXT_API_URL}/users/${ext_id}/sessions_user_all`;
export const LearningBehavior = (ext_id, corp_id = 'ahura') => {
  return `${process.env.REACT_APP_BASE_EXT_API_URL}/users/${ext_id}/${corp_id}/learning`;
};
export const SessionDetailsURL = (session_id = '') => {
  return `${process.env.REACT_APP_BASE_EXT_API_URL}/session/detail/${session_id}`;
};
