import { Resources } from '../../config/Resources';

export const sidebarListItemsManagerandTeacherDashboard = [
  {
    name: 'Home',
    icon: Resources.images.sidebar.Home,
    redirectTo: '/manager-dashboard',
    dropdownItems: [],
  },
  {
    name: 'Library',
    icon: Resources.images.sidebar.Library,
    redirectTo: '/content',
    dropdownItems: [
      { itemName: 'Content', redirectTo: '/content' },
      { itemName: 'Manage Content', redirectTo: '/manage-content' },
    ],
  },
  {
    name: 'License',
    icon: Resources.images.sidebar.License,
    redirectTo: '/myaccount',
    dropdownItems: [
      { itemName: 'Account', redirectTo: '/myaccount' },
      { itemName: 'Member details', redirectTo: '/allocation' },
      { itemName: 'Billing', redirectTo: '#', disabled: true },
      // { itemName: 'Manage Groups', redirectTo: '/manage-groups'}
    ],
  },
];

export const sidebarListItemsNonLoggedIn = [
  {
    name: 'Library',
    icon: Resources.images.sidebar.Library,
    redirectTo: '/content',
    dropdownItems: [],
  }
];

export const sidebarListItemsLearnerDashboard = [
  {
    name: 'Home',
    icon: Resources.images.sidebar.Home,
    redirectTo: '/home',
    dropdownItems: [],
  },
  // {
  //   name: 'Capabilities',
  //   icon: Resources.images.sidebar.Education,
  //   redirectTo: '/capabilities',
  //   dropdownItems: [
  //     { itemName: 'Content', redirectTo: '/content' },
  //     { itemName: ' Curate learning plan', redirectTo: '#', disabled: true },
  //   ],
  // },
  {
    name: 'Library',
    icon: Resources.images.sidebar.Library,
    redirectTo: '/content',
    dropdownItems: [
      { itemName: 'Content', redirectTo: '/content' },
      { itemName: ' Curate learning plan', redirectTo: '#', disabled: true },
    ],
  },
  {
    name: 'License',
    icon: Resources.images.sidebar.License,
    redirectTo: '/myaccount',
    dropdownItems: [
      { itemName: 'Account', redirectTo: '/myaccount' },
      { itemName: 'Billing', redirectTo: '#', disabled: true },
      // { itemName: 'Manage Groups', redirectTo: '/manage-groups'}
    ],
  },
];

// {
//   name: 'Education',
//   icon: Resources.images.sidebar.Education,
//   redirectTo: '/learning-plans',
//   dropdownItems: [
//     { itemName: 'Learning Plans', redirectTo: '/learning-plans' },
//     { itemName: 'Education Sub Tab', redirectTo: '#' },
//   ],
// },
// {
//   name: 'Employees',
//   icon: Resources.images.sidebar.Employees,
//   redirectTo: '#',
//   dropdownItems: [
//     { itemName: 'Employees Sub Tab', redirectTo: '#' },
//     { itemName: 'Education Sub Tab', redirectTo: '#' },
//   ],
// },
// {
//   name: 'Metrics',
//   icon: Resources.images.sidebar.Metrics,
//   redirectTo: '#',
//   dropdownItems: [
//     { itemName: 'Licenses', redirectTo: '#' },
//     { itemName: 'Metrics Sub Tab', redirectTo: '#' },
//   ],
// },
// {
//   name: 'Session Log',
//   icon: Resources.images.sidebar.SessionLog,
//   redirectTo: '#',
//   dropdownItems: [
//     { itemName: 'Session Logs', redirectTo: '#' },
//     { itemName: 'Nudges', redirectTo: '#' },
//   ],
// },
// {
//   name: 'Chat',
//   icon: Resources.images.sidebar.Chat,
//   redirectTo: '#',
//   dropdownItems: [],
// },
// {
//   name: 'Payments',
//   icon: Resources.images.sidebar.Payments,
//   redirectTo: '#',
//   dropdownItems: [
//     { itemName: 'Licenses', redirectTo: '#' },
//     { itemName: 'Allocation', redirectTo: '#' },
//   ],
// },
