import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { CustomTable } from '../../shared/Table';
import CustomTableHead from '../../shared/Table/TableHead';
import { TablePagination, TableBody, TableRow } from '@mui/material';
import {
  TableCellDropdown,
  TableCellSwitch,
  TableCellText,
} from '../../shared/Table/TableCell';
import PanelHeader from '../../../components/shared/Header/PanalHeader';
import { tableHeader } from '../../../helpers/networks/allRegistrants';
import {
  activeOrdeactive,
  getNetworkUsers,
  setNeighborhoodadmin,
  updateAdmin,
  updateIsAdmin,
} from '../../../services/networks';
import ScreenLoader from '../../shared/ScreenLoader';
import Text from '../../shared/Text';
import { useSelector } from 'react-redux';
import NeighborhoodMembers from '../neighbourhoods/neignborhoodMembers';
import useUserData from '../../../hooks/UserData';
import { ROLE_IDS } from '../../../config/Constants';
import c from '../table.module.scss';
const SUPER_USER_CLIENT_ID = 'cb19c961-fc50-4613-87d9-40488220a1d9';

const AllRegistrants = () => {
  const [allRegistrantsData, setAllRegistrantsData] = useState([]);
  const [search, setSearch] = useState('');
  const [initialLoading, setInitialLoading] = useState(true);
  const [allRegistrantsTableHeader, setAllRegistrantsTableHeader] =
    useState(tableHeader);
  const [sort, setSort] = useState({ name: 'asc', date: 'desc' });
  const [sortOrder, setSortOrder] = useState(
    `[["user_client_created_at","desc"]]`
  );
  const [status, setStatus] = useState(null);
  const userData = useUserData();
  const queryClient = useQueryClient();
  const [pageNumber, setPageNumber] = useState({
    totalPages: 0,
    currentPage: 0,
    limit: 25,
  });
  //const [filteredData, setFilteredData] = useState([]); // New state for filtered data

  useEffect(() => {
    if (!initialLoading) {
      const timeOut = setTimeout(() => {
        setStatus(search);
        setPageNumber({
          ...pageNumber,
          currentPage: 0,
        });
      }, 500);
      return () => clearTimeout(timeOut);
    }
  }, [search]);

  const opPageChange = (e, page_number) => {
    setPageNumber({
      ...pageNumber,
      currentPage: page_number,
    });
    window.scrollTo(0, 0);
  };

  const setRowsPerPage = (row_page) => {
    setPageNumber({
      ...pageNumber,
      limit: row_page,
    });
    window.scrollTo(0, 0);
  };

  const { client_name, client_id, role_id, user_id } = useSelector(
    (state) => state?.generalInformation
  );

  const { data, isFetching, refetch } = useQuery(
    ['getAllRegistrants', sort, pageNumber.currentPage, status],
    () =>
      getNetworkUsers({
        type: 'all',
        sort_by:
          allRegistrantsTableHeader[0]?.isSort === true
            ? `[["user_name","${sort.name}"]]`
            : allRegistrantsTableHeader[4]?.isSort === true
              ? `[["user_client_created_at","${sort.date}"]]`
              : '',
        page_number: pageNumber.currentPage + 1,
        limit: pageNumber.limit,
        search: search || "",
      }),
    {
      refetchOnMount: true,
      retry: false,
      refetchOnWindowFocus: false,

      enabled: role_id === ROLE_IDS.PARENT_OWNER || role_id === ROLE_IDS.SUPER_OWNER,
      onSuccess: (data) => {
        if (data?.registrants && data?.registrants.length !== 0) {
          setAllRegistrantsData(data?.registrants);
          setInitialLoading(false);
          setPageNumber({
            ...pageNumber,
            totalPages: data?.total_pages || 0,
          });
        }
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [pageNumber.limit])

  const { mutate: updateAdminFn, isLoading: isSetting } = useMutation(
    updateIsAdmin,
    {
      onSuccess: () => {
        queryClient.refetchQueries('getAllRegistrants');
        queryClient.refetchQueries('getNetworkSummary');
      },
    }
  );

  const updateAdmin = (data) => {
    updateAdminFn({
      id: data.id,
      is_admin: !data?.is_admin,
      loggedInUserClientID: userData.id,
      user_id: data.user_id,
      client_name: client_name,
    });
  };

  const { mutate, isLoading: loadingActiveDeactive } = useMutation(
    activeOrdeactive,
    {
      onSuccess: () => {
        queryClient.refetchQueries('getAllRegistrants');
      },
    }
  );

  const sortHandler = (sortCellName) => {
    setSort({
      ...sort,
      [sortCellName]: sort[sortCellName] === 'asc' ? 'desc' : 'asc',
    });
    console.log(sortCellName);
    let temp = allRegistrantsTableHeader;
    if (sortCellName === 'name') {
      temp[0].isSort = true;
      temp[4].isSort = false;
    } else if (sortCellName === 'date') {
      temp[0].isSort = false;
      temp[4].isSort = true;
    }
    setAllRegistrantsTableHeader(temp);
  };

  const handleActionChange = (value, id, item) => {
    if (value) {
      const data = {
        id: item.user_id,
        status: value === 'Active' ? true : false,
      };
      mutate(data);
    }
  };

  return (
    <div className="w-100">
      <PanelHeader
        heading="All Registrants"
        isSearch={true}
        isSelect={false}
        setSearch={setSearch}
      />
      {role_id === ROLE_IDS.PARENT_OWNER ||
        role_id === ROLE_IDS.SUPER_OWNER ? (
        initialLoading || isFetching || loadingActiveDeactive ? (
          <ScreenLoader />
        ) : allRegistrantsData.length > 0 ? (
          <>
            <CustomTable classToOverride="w-100 px-4 my-2">
              <CustomTableHead
                headerData={allRegistrantsTableHeader}
                sortHandler={sortHandler}
                sort={sort}
              />
              <TableBody>
                {allRegistrantsData
                  // .filter(({ user_name }) =>
                  //   user_name?.toLowerCase().trim().includes(search)
                  // )
                  // .slice(0, 100)
                  .map((user) => (
                    <TableRow key={user.id} hover>
                      <TableCellText text={user?.user_name} />
                      <TableCellText
                        text={
                          user?.network === 'client' ||
                            user?.network === 'clients'
                            ? 'Neighborhood'
                            : user?.network === 'users'
                              ? 'Citizen'
                              : '-'
                        }
                      />
                      <TableCellText
                        text={user?.client_id != SUPER_USER_CLIENT_ID ? user.client_name : '-'}
                      />
                      <TableCellText text={user?.email} />
                      <TableCellText
                        text={user?.user_client_created_at.slice(0, 10)}
                      />
                      <TableCellSwitch
                        checked={user?.is_admin}
                        onClick={() => updateAdmin(user)}
                      />
                      <TableCellDropdown
                        btnName={
                          user.is_user_account_active ? 'Active' : 'Inactive'
                        }
                        options={[
                          { id: 1, value: 'Active' },
                          { id: 0, value: 'Inactive' },
                        ]}
                        item={user}
                        setValue={handleActionChange}
                      />
                    </TableRow>
                  ))}
              </TableBody>
            </CustomTable>
            <div className="d-flex align-items-center justify-content-center py-5">
              {
                <TablePagination
                  className={c.tablePagination}
                  component="div"
                  count={data?.pagination.totalRecords}
                  page={pageNumber?.currentPage}
                  rowsPerPageOptions={[10, 25, 100]}
                  rowsPerPage={pageNumber?.limit}
                  onPageChange={(e, page) => {
                    opPageChange(e, page);
                  }}
                  onRowsPerPageChange={(e) => {
                    setRowsPerPage(e?.target?.value)
                  }}
                />
              }
            </div>
          </>
        ) : (
          <Text className="text-center mt-5 fs-4 font-weight-bold">
            No Record Found
          </Text>
        )
      ) : role_id === ROLE_IDS.CLIENT_OWNER ||
        role_id === ROLE_IDS.CLIENT_ADMIN ? (
        <>
          <Text className="font-weight-bold px-4">
            License Code: {client_id || ""}
          </Text>
          <NeighborhoodMembers
            corporateId={client_id}
            showBackButton={false}
            showPanelHeader={false}
            setSearch={setSearch}
            searchString={search}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AllRegistrants;
