/**
 * @function RightPanelPage2
 * @param {number} goToNextPage
 * @returns It will show second page of right panel.
 */

import React from 'react';
import RightPanelLayout from '../../RightPanelLayout';
import SignupStyling from '../../signUp.Module.scss';
import { TextField, Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormik, ErrorMessage } from 'formik';
import { makeStyles } from '@mui/styles';
import Form from 'react-bootstrap/Form';
import {
  initialValues,
  UserDataValidationSchema,
} from '../../../../helpers/signup';
import { onSubmit } from '../../../../helpers/signup';
const RightPanelPage2 = (props) => {
  const formik = useFormik({
    initialValues: { ...initialValues, email: props?.emailId || '' },
    validationSchema: UserDataValidationSchema,
    onSubmit,
  });

  const styles = makeStyles((theme) => ({
    label: {
      fontSize: '14px',
    },
    root: {
      '& .MuiFilledInput-root': {
        background: '#c6d3d2',
      },
    },
  }));
  const classes = styles();
  return (
    <>
      <RightPanelLayout
        formik={formik}
        heading="Register"
        desc="Enter your information to complete the registration process with Ahura AI."
        goToNextPage={props.goToNextPage}
      >
        <form>
          <TextField
            type="text"
            name="first_name"
            placeholder="First Name"
            variant="outlined"
            id="first_name"
            inputProps={{ className: ` fw-bold ${SignupStyling.signUpFields}` }}
            className={`${SignupStyling.signUpFieldsContainer} w-100 mb-2`}
            value={formik?.values?.first_name}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={
              (formik?.touched.first_name || formik?.touched.sumbited) &&
              formik?.errors?.first_name
                ? true
                : false
            }
            helperText={
              (formik?.touched.first_name || formik?.touched.sumbited) &&
              formik?.errors?.first_name
            }
          />
          <TextField
            type="text"
            value={formik?.values?.last_name}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={
              (formik?.touched.last_name || formik?.touched.sumbited) &&
              formik?.errors?.last_name
                ? true
                : false
            }
            helperText={
              (formik?.touched.last_name || formik?.touched.sumbited) &&
              formik?.errors?.last_name
            }
            name="last_name"
            id="last_name"
            placeholder="Last Name"
            variant="outlined"
            inputProps={{ className: `${SignupStyling.signUpFields} fw-bold ` }}
            className={`${SignupStyling.signUpFieldsContainer} w-100 mb-2`}
          />

          <TextField
            type="email"
            value={formik?.values?.email}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={
              (formik?.touched?.email || formik?.touched.sumbited) &&
              formik?.errors?.email
                ? true
                : false
            }
            helperText={
              (formik?.touched?.email || formik?.touched.sumbited) &&
              formik?.errors?.email
            }
            name="email"
            id="email"
            placeholder="Email"
            variant="outlined"
            inputProps={{ className: ` fw-bold ${SignupStyling.signUpFields}` }}
            className={`${SignupStyling.signUpFieldsContainer} w-100 mb-2`}
          />
          <FormControlLabel
            classes={{
              label: classes.label,
            }}
            control={
              <Form.Check
                type="checkbox"
                id={`check-api-checkbox`}
                className="mx-2"
              >
                <Form.Check.Input
                  type="checkbox"
                  isValid={formik?.values?.accept_terms ? true : false}
                  isInvalid={
                    formik?.values?.accept_terms === false &&
                    formik?.touched.sumbited
                      ? true
                      : false
                  }
                  required={true}
                  className={SignupStyling.crossIcon}
                  onChange={formik?.handleChange}
                  name="accept_terms"
                  id="accept_terms"
                />
              </Form.Check>
            }
            label={
              <>
                Ahura AI is committed to leading industry data protection
                standards, and uses your data to personalize your learning
                experience. Check to agree to our{' '}
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={SignupStyling.link}
                >
                  Terms and Conditions
                </a>{' '}
                and{' '}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={SignupStyling.link}
                >
                  Privacy Policy
                </a>
                .
              </>
            }
            name="accept_terms"
            id="accept_terms"
            onChange={formik?.handleChange}
          />
        </form>
      </RightPanelLayout>
    </>
  );
};
export default RightPanelPage2;
