import React, { useState, useEffect, useRef } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
  IconButton,
  Typography,
  Box,
  Collapse,
  TextField,
  FormControlLabel,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import {
  addQuestion,
  updateQuestion,
  deleteQuestion,
  deleteQuestionOption,
  updateQuestionSortOrder,
  getAssignmentQuestionsForAdmin,
} from '../../../../../helpers/Quiz';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import c from './assignments.module.scss';
import classNames from 'classnames';

const QUESTION_TYPE = {
  MULTIPLE_CHOICE: 1,
  TRUE_FALSE: 2,
  ESSAY: 3,
};

const AssignmentsList = ({
  assignments,
  onEdit,
  onDelete,
  setIsDragEnabled,
}) => {
  const [expanded, setExpanded] = useState({});
  const [questions, setQuestions] = useState({});
  const [showNewQuestionCard, setShowNewQuestionCard] = useState(false);
  const [newQuestionText, setNewQuestionText] = useState('');
  const [newQuestionType, setNewQuestionType] = useState(
    QUESTION_TYPE.MULTIPLE_CHOICE
  );
  const [newOptions, setNewOptions] = useState([
    { option_value: '', isCorrect: false }, // Updated property name
  ]);
  const [deleteQuestionDialogOpen, setDeleteQuestionDialogOpen] =
    useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const [assignmentIdToDelete, setAssignmentIdToDelete] = useState(null);
  const [deleteOptionDialogOpen, setDeleteOptionDialogOpen] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState(null);
  const [questionIdToDeleteOption, setQuestionIdToDeleteOption] =
    useState(null);
  const [isEssayCorrect, setIsEssayCorrect] = useState(false); // State for essay correctness
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const { user_id } = useSelector((state) => state?.generalInformation);

  const isMounted = useRef(true); // Create a ref to track mounted status
  const [pendingChanges, setPendingChanges] = useState([]); // Track changes to questions

  useEffect(() => {
    // Set isMounted to true when the component is mounted
    isMounted.current = true;

    // Cleanup function to set the ref to false when the component unmounts
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleToggle = async (assignmentId) => {
    if (expanded === assignmentId) {
      // Collapse if the same assignment is clicked
      setExpanded(null);

      return;
    }
    setExpanded((prev) => {
      const newExpanded = { ...prev, [assignmentId]: !prev[assignmentId] };
      return newExpanded;
    });

    if (!expanded[assignmentId]) {
      setIsDragEnabled(false);
      try {
        const data = await getAssignmentQuestionsForAdmin(assignmentId);
        setQuestions((prev) => ({ ...prev, [assignmentId]: data || [] }));
      } catch (error) {
        console.error('Error fetching assignment questions:', error);
      }
    } else {
      setIsDragEnabled(true);
    }
  };

  // Handle Add New Question (without clearing previously added questions)
  const handleAddNewQuestion = () => {
    // Reset all form fields and state
    setNewQuestionText('');
    setNewQuestionType(QUESTION_TYPE.MULTIPLE_CHOICE);
    setNewOptions([{ option_value: '', isCorrect: false }]);
    setIsEssayCorrect(false);
    setCurrentQuestionId(null); // Reset current question ID for new entry
    setShowNewQuestionCard(true); // Show a new question card
  };

  const handleAddQuestion = async (assignmentId) => {
    // Prevent saving if question text or options are empty
    if (!newQuestionText || newOptions.some((option) => !option.option_value)) {
      return;
    }
    setLoading(true);
    setMessage('Saving Assignment...');

    const questionData = {
      question_title: newQuestionText,
      question_type: newQuestionType,
      options:
        newQuestionType === QUESTION_TYPE.ESSAY
          ? [
              {
                option_value: newOptions[0].option_value,
                is_correct: isEssayCorrect,
              },
            ]
          : newOptions.map((option) => ({
              option_value: option.option_value,
              is_correct: option.isCorrect,
            })),
      created_by: user_id,
      updated_by: user_id,
    };

    try {
      // Call the API to add the question
      const response = await addQuestion(assignmentId, questionData);
      if (response?.question) {
        // Create the new question object to match frontend format
        const newQuestion = {
          ...response.question,
          question_options_attributes:
            response.question.question_options_attributes || [],
        };

        // Update the questions state only once
        setQuestions((prev) => {
          const updatedQuestions = { ...prev };
          if (!updatedQuestions[assignmentId]) {
            updatedQuestions[assignmentId] = [];
          }

          // Check if the question already exists to avoid duplicate additions
          const exists = updatedQuestions[assignmentId].some(
            (question) => question.id === newQuestion.id
          );
          if (!exists) {
            updatedQuestions[assignmentId].push(newQuestion);
          }
          return updatedQuestions;
        });

        // Display a flash message indicating successful save
        setTimeout(() => {
          setMessage('Added');
        }, 200);

        // Set currentQuestionId to enable "Update" mode
        setCurrentQuestionId(newQuestion.id);

        // Reset form fields after saving
        setNewQuestionText('');
        setNewQuestionType(QUESTION_TYPE.MULTIPLE_CHOICE);
        setNewOptions([{ option_value: '', isCorrect: false }]);
        setIsEssayCorrect(false);

        // Close the new question card
        setShowNewQuestionCard(false);
      }
    } catch (error) {
      console.error('Error adding question:', error);
    } finally {
      setTimeout(() => {
        setLoading(false);
        setMessage(''); // Clear the message after loading stops
      }, 600);
    }
  };

  const handleUpdateQuestion = async (assignmentId, updatedQuestion) => {
    setLoading(true); // Start loading
    setMessage('Updating Assignment...'); // Set the message

    const updatedData = {
      question_title: updatedQuestion.question_title,
      question_type: updatedQuestion.question_type,
      options: updatedQuestion.question_options_attributes.map((option) => ({
        id: option.id,
        option_value: option.option_value,
        is_correct: option.is_correct,
      })),
      updated_by: user_id,
    };

    try {
      // Update the existing question
      const response = await updateQuestion(updatedQuestion.id, updatedData);

      if (response && response.question) {
        // Update the question in the state with the full response data
        setQuestions((prev) => ({
          ...prev,
          [assignmentId]: prev[assignmentId].map((question) =>
            question.id === updatedQuestion.id
              ? {
                  ...response.question,
                  question_options_attributes:
                    response.question.question_options_attributes,
                }
              : question
          ),
        }));

        // Display a flash message indicating successful save
        setTimeout(() => {
          setMessage('Saved');
        }, 200);

        // Optionally, reset the form for the next update
        setNewQuestionText('');
        setNewQuestionType(QUESTION_TYPE.MULTIPLE_CHOICE);
        setNewOptions([{ option_value: '', isCorrect: false }]);
        setIsEssayCorrect(false);
        setCurrentQuestionId(null); // Reset after update
        setShowNewQuestionCard(true); // Show a new question card
      }
    } catch (error) {
      console.error('Error updating question:', error);
    } finally {
      // Close the delete dialog
      if (isMounted.current) {
        setDeleteQuestionDialogOpen(false);
      }
      // Keep loading for a bit longer (e.g., 1 second) to show the flash message
      setTimeout(() => {
        setLoading(false);
        setMessage(''); // Clear the message after loading stops
      }, 600);
    }
  };

  const handleDeleteQuestion = (assignmentId, questionId) => {
    setQuestionToDelete(questionId);
    setAssignmentIdToDelete(assignmentId);
    setDeleteQuestionDialogOpen(true);
  };

  const confirmDeleteQuestion = async () => {
    setLoading(true);
    setMessage('Deleting Question and Updating Assignment...');
    try {
      const resetQuestions = () => {
        // Ensure we create a new state object to trigger a re-render
        setQuestions((prev) => {
          const updatedQuestions = prev[assignmentIdToDelete]?.filter(
            (q) => q.id !== questionToDelete
          );

          // Return a new state object
          return {
            ...prev,
            [assignmentIdToDelete]: updatedQuestions || [],
          };
        });
      };
      // Call the delete API function
      if (questionToDelete) {
        const response = await deleteQuestion(questionToDelete);
        if (response) {
          resetQuestions();
          // Display a flash message indicating successful save
          setTimeout(() => {
            setMessage('Deleted');
          }, 200);

          console.log('Question deleted successfully!');
        } else {
          console.error('Failed to delete the question.');
        }
      } else if (questionToDelete === null) {
        console.log('questionToDelete', questionToDelete);
        resetQuestions();
      }
      console.log('questionToDelete', questionToDelete);
    } catch (error) {
      console.error('Error deleting the question:', error);
    } finally {
      // Close the delete dialog
      if (isMounted.current) {
        setDeleteQuestionDialogOpen(false);
      }
      // Keep loading for a bit longer (e.g., 1 second) to show the flash message
      setTimeout(() => {
        setLoading(false);
        setMessage(''); // Clear the message after loading stops
      }, 600);
    }
  };

  const handleEditQuestion = (assignmentId, questionId, title, type) => {
    setQuestions((prev) => ({
      ...prev,
      [assignmentId]: prev[assignmentId].map((q) =>
        q.id === questionId
          ? { ...q, question_title: title, question_type: type }
          : q
      ),
    }));

    // Set current question ID for editing
    setCurrentQuestionId(questionId);
    setNewQuestionText(title);
    setNewQuestionType(type);
    // Assuming question options are also set here, handle them as necessary
  };

  const handleAddOption = (questionId, questionType) => {
    setQuestions((prevQuestions) =>
      Object.keys(prevQuestions).reduce((acc, assignmentId) => {
        acc[assignmentId] = prevQuestions[assignmentId].map((question) => {
          if (question.id === questionId) {
            const { question_type, question_options_attributes } = question;

            // Enforce rule for Essay question: allow adding at most 1 option
            if (
              question_type === QUESTION_TYPE.ESSAY &&
              question_options_attributes.length < 1
            ) {
              return {
                ...question,
                question_options_attributes: [
                  ...question_options_attributes,
                  { option_value: '', isCorrect: false },
                ],
              };
            }

            // Allow adding options for other types of questions (e.g., Multiple Choice, True/False)
            if (question_type !== QUESTION_TYPE.ESSAY) {
              return {
                ...question,
                question_options_attributes: [
                  ...question_options_attributes,
                  { option_value: '', isCorrect: false },
                ],
              };
            }

            // No change if Essay question already has 1 option
            return question;
          }
          return question;
        });
        return acc;
      }, {})
    );

    // Clear the fields after adding an option (reset the form for next entry)
    setNewOptions([{ option_value: '', isCorrect: false }]);
  };

  const handleOptionChange = (type, index, value) => {
    // For the 'new' options state, directly mutate based on 'type' ('new')
    if (type === 'new') {
      setNewOptions((prevOptions) => {
        const updatedOptions = [...prevOptions];
        updatedOptions[index] = {
          ...updatedOptions[index],
          option_value: value,
        };
        return updatedOptions;
      });
    } else {
      setQuestions((prevQuestions) =>
        Object.keys(prevQuestions).reduce((acc, assignmentId) => {
          acc[assignmentId] = prevQuestions[assignmentId].map((question) => {
            if (question.id === type) {
              const updatedOptions = [...question.question_options_attributes];
              updatedOptions[index] = {
                ...updatedOptions[index],
                option_value: value,
              };
              return {
                ...question,
                question_options_attributes: updatedOptions,
              };
            }
            return question;
          });
          return acc;
        }, {})
      );
    }
  };

  const handleCorrectOptionChange = (type, index, assignmentId) => {
    if (type === 'new') {
      // For new questions, we are using true/false
      setNewOptions((prevOptions) => {
        const updatedOptions = prevOptions.map((option, idx) => ({
          ...option,
          isCorrect: idx === index, // Only one option can be correct
        }));
        return updatedOptions;
      });
    } else {
      // For existing questions (with 1/0), toggle correctness
      setQuestions((prevQuestions) =>
        Object.keys(prevQuestions).reduce((acc, currentAssignmentId) => {
          if (currentAssignmentId === assignmentId) {
            acc[currentAssignmentId] = prevQuestions[currentAssignmentId].map(
              (question) => {
                if (question.id === type) {
                  const updatedOptions =
                    question.question_options_attributes.map((opt, idx) => ({
                      ...opt,
                      is_correct: idx === index ? 1 : 0, // Set correct option to 1, others to 0
                    }));
                  return {
                    ...question,
                    question_options_attributes: updatedOptions,
                  };
                }
                return question;
              }
            );
          }
          return acc;
        }, {})
      );
    }
  };

  const handleDeleteOption = (assignmentId, questionId, option) => {
    setOptionToDelete(option);
    setQuestionIdToDeleteOption(questionId);
    setDeleteOptionDialogOpen(true);
  };
  const confirmDeleteOption = async () => {
    setLoading(true); // Start loading
    setMessage('Deleting Question Option...'); // Set the message

    const resetOptions = () => {
      // Update the state to remove the deleted option
      setQuestions((prevQuestions) =>
        Object.keys(prevQuestions).reduce((acc, assignmentId) => {
          // For each assignment
          acc[assignmentId] = prevQuestions[assignmentId].map((question) => {
            // Check if this question matches the one where we want to delete an option
            if (question.id === questionIdToDeleteOption) {
              // Remove the option from question_options_attributes
              const updatedOptions =
                question.question_options_attributes.filter(
                  (opt) => opt.id !== optionToDelete.id
                );
              // Return the updated question with the filtered options
              return {
                ...question,
                question_options_attributes: updatedOptions,
              };
            }
            return question; // If this is not the correct question, return it unchanged
          });
          return acc; // Return the modified assignments object
        }, {})
      );
    };
    try {
      // Call the API to delete the question option
      if (optionToDelete.id) {
        const response = await deleteQuestionOption(optionToDelete.id);
        if (response) {
          // Log success or show a notification
          resetOptions();
          // Display a flash message indicating successful save
          setTimeout(() => {
            setMessage('Option Deleted');
          }, 200);
          console.log('Option deleted successfully:', response);
        } else {
          console.error('Failed to delete the option.');
        }
      }
      console.log('optionToDelete', optionToDelete.id);
      if (optionToDelete.id === undefined) {
        resetOptions();
      }
    } catch (error) {
      console.error('Error deleting the option:', error);
    } finally {
      // Close the delete dialog
      setDeleteOptionDialogOpen(false);
      setTimeout(() => {
        setLoading(false);
        setMessage(''); // Clear the message after loading stops
      }, 600);
    }
  };

  const handleDragEnd = async (result, assignmentId) => {
    const { destination, source } = result;
    if (!destination) return;
    const reorderedItems = Array.from(questions[assignmentId]);
    const [removed] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, removed);

    setQuestions((prev) => ({
      ...prev,
      [assignmentId]: reorderedItems,
    }));

    const sortedQuestions = reorderedItems.map((item, index) => ({
      id: item.id,
      sort_order: index,
    }));
    try {
      await updateQuestionSortOrder(assignmentId, sortedQuestions);
    } catch (error) {
      console.error('Error updating sort order of questions:', error);
    }
  };

  return (
    <div className={classNames(c.container)}>
      {/* Circular Progress and Message */}
      {loading && (
        <div className={classNames(c.overlay)}>
          <div className={classNames(c.progressBarContainer)}>
            <CircularProgress size={100} />
            <Typography
              variant="body2"
              className={classNames(c.loadingMessage)}
            >
              {message}
            </Typography>
          </div>
        </div>
      )}

      <List>
        {assignments.length === 0 ? (
          <ListItem>
            <ListItemText primary="No assignments available." />
          </ListItem>
        ) : (
          assignments.map((assignment) => (
            <React.Fragment key={assignment.id}>
              <ListItem divider>
                <ListItemText
                  primary={
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="subtitle1"
                        style={{ marginRight: 8 }}
                      >
                        {assignment.assignment_name}
                      </Typography>
                      <Chip
                        label={assignment.is_active ? 'Active' : 'Inactive'}
                        color={assignment.is_active ? 'success' : 'error'}
                        variant="solid"
                      />
                    </Box>
                  }
                  secondary={`Created At: ${new Date(
                    assignment.created_at
                  ).toLocaleDateString()}`}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => onEdit(assignment)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" onClick={() => onDelete(assignment)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    onClick={() => handleToggle(assignment.id)}
                  >
                    {expanded[assignment.id] ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse in={expanded[assignment.id]}>
                <DragDropContext
                  onDragEnd={(result) => handleDragEnd(result, assignment.id)}
                >
                  <Droppable droppableId={assignment.id.toString()}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{ marginBottom: '10px' }}
                      >
                        {questions[assignment.id]?.map((question, index) => (
                          <Draggable
                            key={question.id}
                            draggableId={question.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <Card
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{ marginBottom: 2 }}
                              >
                                <CardContent>
                                  <TextField
                                    label="Question"
                                    value={question.question_title}
                                    onChange={(e) =>
                                      handleEditQuestion(
                                        assignment.id,
                                        question.id,
                                        e.target.value,
                                        question.question_type
                                      )
                                    }
                                    fullWidth
                                    margin="normal"
                                    sx={{ width: { xs: '100%', md: '50%' } }} // Responsive width
                                  />
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                  >
                                    <InputLabel>Question Type</InputLabel>
                                    <Select
                                      value={question.question_type}
                                      onChange={(e) =>
                                        handleEditQuestion(
                                          assignment.id,
                                          question.id,
                                          question.question_title,
                                          e.target.value
                                        )
                                      }
                                      label="Question Type"
                                    >
                                      <MenuItem
                                        value={QUESTION_TYPE.MULTIPLE_CHOICE}
                                      >
                                        Multiple Choice
                                      </MenuItem>
                                      <MenuItem
                                        value={QUESTION_TYPE.TRUE_FALSE}
                                      >
                                        True/False
                                      </MenuItem>
                                      <MenuItem value={QUESTION_TYPE.ESSAY}>
                                        Essay
                                      </MenuItem>
                                    </Select>
                                  </FormControl>

                                  <Box mt={2}>
                                    {question.question_options_attributes?.map(
                                      (option, index) => (
                                        <Box
                                          key={index}
                                          display="flex"
                                          alignItems="center"
                                          mb={2}
                                          justifyContent="space-between"
                                        >
                                          <TextField
                                            label={`Option ${index + 1}`}
                                            value={option.option_value}
                                            onChange={
                                              (e) =>
                                                handleOptionChange(
                                                  question.id,
                                                  index,
                                                  e.target.value
                                                ) // Update option value on change
                                            }
                                            fullWidth
                                            margin="normal"
                                            sx={{ marginRight: 2 }} // Right margin for spacing
                                          />
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  option.is_correct === 1
                                                } // Check if the option is correct
                                                onChange={() =>
                                                  handleCorrectOptionChange(
                                                    question.id,
                                                    index,
                                                    assignment.id
                                                  )
                                                }
                                              />
                                            }
                                            label="Correct Option"
                                            sx={{
                                              marginLeft: 2,
                                              whiteSpace: 'nowrap',
                                            }}
                                          />
                                          <IconButton
                                            onClick={() =>
                                              handleDeleteOption(
                                                assignment.id,
                                                question.id,
                                                option
                                              )
                                            }
                                          >
                                            <CancelIcon />
                                          </IconButton>
                                        </Box>
                                      )
                                    )}
                                  </Box>

                                  {/* Conditionally show the "Add Option" button */}

                                  {(question.question_type ===
                                    QUESTION_TYPE.MULTIPLE_CHOICE ||
                                    (question.question_type ===
                                      QUESTION_TYPE.TRUE_FALSE &&
                                      question.question_options_attributes
                                        ?.length < 2) ||
                                    (question.question_type ===
                                      QUESTION_TYPE.ESSAY &&
                                      question.question_options_attributes
                                        ?.length < 1)) && (
                                    <Button
                                      onClick={() =>
                                        handleAddOption(
                                          question.id,
                                          question.question_type
                                        )
                                      }
                                      variant="outlined"
                                    >
                                      Add Option
                                    </Button>
                                  )}
                                </CardContent>
                                <CardActions>
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      handleUpdateQuestion(
                                        assignment.id,
                                        question
                                      );
                                    }}
                                  >
                                    Update
                                  </Button>
                                  <IconButton
                                    onClick={() =>
                                      handleDeleteQuestion(
                                        assignment.id,
                                        question.id
                                      )
                                    }
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </CardActions>
                              </Card>
                            )}
                          </Draggable>
                        ))}

                        {showNewQuestionCard && (
                          <Card variant="outlined" sx={{ marginBottom: 2 }}>
                            <CardContent>
                              <TextField
                                label="New Question"
                                value={newQuestionText}
                                onChange={(e) =>
                                  setNewQuestionText(e.target.value)
                                } // Handle new question text
                                fullWidth
                                margin="normal"
                              />
                              <FormControl
                                fullWidth
                                variant="outlined"
                                margin="normal"
                              >
                                <InputLabel>Question Type</InputLabel>
                                <Select
                                  value={newQuestionType}
                                  onChange={(e) =>
                                    setNewQuestionType(e.target.value)
                                  } // Handle question type change
                                  label="Question Type"
                                >
                                  <MenuItem
                                    value={QUESTION_TYPE.MULTIPLE_CHOICE}
                                  >
                                    Multiple Choice
                                  </MenuItem>
                                  <MenuItem value={QUESTION_TYPE.TRUE_FALSE}>
                                    True/False
                                  </MenuItem>
                                  <MenuItem value={QUESTION_TYPE.ESSAY}>
                                    Essay
                                  </MenuItem>
                                </Select>
                              </FormControl>

                              {/* Option Handling */}
                              {newQuestionType === QUESTION_TYPE.ESSAY && (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  mb={2}
                                  justifyContent="space-between"
                                >
                                  <TextField
                                    label="Option"
                                    value={newOptions[0]?.option_value || ''}
                                    onChange={(e) =>
                                      handleOptionChange(
                                        'new',
                                        0,
                                        e.target.value
                                      )
                                    }
                                    fullWidth
                                    margin="normal"
                                    sx={{ marginRight: 2 }}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={isEssayCorrect}
                                        onChange={() =>
                                          setIsEssayCorrect(!isEssayCorrect)
                                        }
                                      />
                                    }
                                    label="Correct Option"
                                    sx={{ marginLeft: 2, whiteSpace: 'nowrap' }}
                                  />
                                </Box>
                              )}

                              {/* Handling True/False Type */}
                              {newQuestionType === QUESTION_TYPE.TRUE_FALSE && (
                                <>
                                  {newOptions
                                    .slice(0, 2)
                                    .map((option, index) => (
                                      <Box
                                        key={index}
                                        display="flex"
                                        alignItems="center"
                                        mb={2}
                                        justifyContent="space-between"
                                      >
                                        <TextField
                                          label={`Option ${index + 1}`}
                                          value={option.option_value}
                                          onChange={(e) =>
                                            handleOptionChange(
                                              'new',
                                              index,
                                              e.target.value
                                            )
                                          }
                                          fullWidth
                                          margin="normal"
                                          sx={{ marginRight: 2 }}
                                        />
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={option.isCorrect} // For new question, use true/false
                                              onChange={() =>
                                                handleCorrectOptionChange(
                                                  'new',
                                                  index,
                                                  assignment.id
                                                )
                                              }
                                            />
                                          }
                                          label="Correct Option"
                                        />
                                      </Box>
                                    ))}
                                  {newOptions.length < 2 && (
                                    <Button
                                      onClick={() =>
                                        setNewOptions([
                                          ...newOptions,
                                          {
                                            option_value: '',
                                            isCorrect: false,
                                          },
                                        ])
                                      }
                                      variant="outlined"
                                    >
                                      Add Option
                                    </Button>
                                  )}
                                </>
                              )}

                              {/* Handling Multiple Choice Type */}
                              {newQuestionType ===
                                QUESTION_TYPE.MULTIPLE_CHOICE && (
                                <>
                                  {newOptions.map((option, index) => (
                                    <Box
                                      key={index}
                                      display="flex"
                                      alignItems="center"
                                      mb={2}
                                      justifyContent="space-between"
                                    >
                                      <TextField
                                        label={`Option ${index + 1}`}
                                        value={option.option_value}
                                        onChange={(e) =>
                                          handleOptionChange(
                                            'new',
                                            index,
                                            e.target.value
                                          )
                                        } // Handle option value change
                                        fullWidth
                                        margin="normal"
                                        sx={{ marginRight: 2 }}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={option.isCorrect}
                                            onChange={() =>
                                              handleCorrectOptionChange(
                                                'new',
                                                index
                                              )
                                            }
                                          />
                                        }
                                        label="Correct Option"
                                        sx={{
                                          marginLeft: 2,
                                          whiteSpace: 'nowrap',
                                        }}
                                      />
                                    </Box>
                                  ))}
                                  <Button
                                    onClick={() =>
                                      setNewOptions([
                                        ...newOptions,
                                        { option_value: '', isCorrect: false },
                                      ])
                                    }
                                    variant="outlined"
                                  >
                                    Add Option
                                  </Button>
                                </>
                              )}
                            </CardContent>
                            <CardActions>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  if (currentQuestionId) {
                                    // Update the existing question
                                    handleUpdateQuestion(assignment.id, {
                                      id: currentQuestionId,
                                      question_title: newQuestionText,
                                      question_type: newQuestionType,
                                      options: newOptions,
                                    });
                                  } else {
                                    // Add a new question
                                    handleAddQuestion(assignment.id);
                                  }
                                }}
                              >
                                {currentQuestionId ? 'Update' : 'Save'}
                              </Button>

                              <IconButton
                                onClick={() =>
                                  handleDeleteQuestion(assignment.id, null)
                                }
                              >
                                <CancelIcon />
                              </IconButton>
                            </CardActions>
                          </Card>
                        )}

                        <Button
                          onClick={handleAddNewQuestion}
                          variant="contained"
                          sx={{ marginTop: 2 }}
                        >
                          Add Question
                        </Button>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Collapse>
            </React.Fragment>
          ))
        )}
        <Dialog
          open={deleteQuestionDialogOpen}
          onClose={() => setDeleteQuestionDialogOpen(false)}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this question? This action cannot
              be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteQuestionDialogOpen(false)}>
              Cancel
            </Button>
            <Button onClick={confirmDeleteQuestion} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteOptionDialogOpen}
          onClose={() => setDeleteOptionDialogOpen(false)}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this option? This action cannot be
              undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteOptionDialogOpen(false)}>
              Cancel
            </Button>
            <Button onClick={confirmDeleteOption} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </List>
    </div>
  );
};

export default AssignmentsList;
