import React, { useState, useEffect } from 'react';
import ControlPointSharpIcon from '@mui/icons-material/ControlPointSharp';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  CircularProgress,
  Button,
  TextField,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Modal,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import classNames from 'classnames';
import manageCourses from '../../../manageCourses.module.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SaveIcon from '@mui/icons-material/Save';

const Lesson = ({
  index,
  lessonTemplate,
  lessonData,
  lessonIsSaved,
  onSave,
  lessonLoading,
}) => {
  const [lessonId, setLessonId] = useState(lessonData.id);
  const [lessonHeading, setLessonHeading] = useState(lessonData.heading || '');
  const [description, setDescription] = useState(lessonData.description || '');
  const [attachments, setAttachments] = useState(lessonData.attachments || []);
  const [modalOpen, setModalOpen] = useState(false);
  const [attachmentName, setAttachmentName] = useState('');
  const [attachmentType, setAttachmentType] = useState('Text'); // Default type

  useEffect(() => {
    if ((lessonData && lessonData.id) || lessonIsSaved?.is_saved) {
      setLessonId(lessonData.id);
      setLessonHeading(lessonData.heading || '');
      setDescription(lessonData.description || '');
      setAttachments(lessonData.attachments || []);
    } else {
      // Reset form when switching to new lesson creation
      setLessonId(undefined);
      setLessonHeading('');
      setDescription('');
      setAttachments([]);
    }
  }, [lessonData, lessonIsSaved]);

  const handleAttachmentChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const updatedAttachments = [...attachments];
      updatedAttachments[index].file = file;
      setAttachments(updatedAttachments);
    }
  };

  const handleAddAttachment = () => {
    const newAttachment = {
      name: attachmentName,
      type: attachmentType,
      file: null, // Initialize with no file
    };
    setAttachments([...attachments, newAttachment]);
    setModalOpen(false);
    setAttachmentName('');
    setAttachmentType('Text');
  };

  const handleDeleteAttachment = (index) => {
    const updatedAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(updatedAttachments);
  };

  const handleSaveLesson = () => {
    console.log('Trying to save >>>>');
    const updatedLessonData = {
      ...lessonTemplate,
      id: lessonId || undefined, // undefined if it's a new lesson
      heading: lessonHeading,
      description,
      attachments,
    };

    // // Set the new lessons list with updatedLessonData

    // if (updatedLessonData.id) {
    //   console.log('Update Operation for ', updatedLessonData.id);
    // } else {
    //   console.log(
    //     'Create Operation because updatedLessonData.id is ',
    //     updatedLessonData.id
    //   );
    // }
    onSave(updatedLessonData); // Trigger the save function passed from parent
  };

  return (
    <Box
      className={classNames(manageCourses.container, 'p-4')}
      sx={{ borderRadius: 2 }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            {lessonData?.id || lessonIsSaved?.lesson_id
              ? 'Update Lesson Title'
              : 'Add Lesson Title'}
          </Typography>
          <TextField
            label="Lesson Title"
            variant="outlined"
            size="small"
            value={lessonHeading}
            onChange={(e) => setLessonHeading(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Enter Description
          </Typography>
          <ReactQuill
            value={description}
            onChange={setDescription}
            theme="snow"
            style={{ minHeight: '100px', marginBottom: '16px' }}
          />
        </Grid>

        {/* Add Attachment Section */}
        {/* <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Add Attachment
          </Typography>
          <Grid container spacing={2}>
            {attachments.map((attachment, index) => (
              <Grid item key={index}>
                <Card
                  variant="outlined"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    width: 120,
                    height: 150,
                    borderRadius: 3,
                    position: 'relative',
                  }}
                >
                  <CancelIcon
                    sx={{
                      position: 'absolute',
                      top: 5,
                      right: 5,
                      cursor: 'pointer',
                      color: 'grey.600',
                    }}
                    onClick={() => handleDeleteAttachment(index)}
                  />
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexGrow: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {attachment.file ? (
                      <Typography variant="body2" color="textSecondary">
                        {attachment.file.name}
                      </Typography>
                    ) : (
                      <>
                        <input
                          type="file"
                          accept={
                            attachment.type === 'Audio'
                              ? 'audio/*'
                              : attachment.type === 'Video'
                              ? 'video/*'
                              : 'text/plain'
                          }
                          style={{ display: 'none' }}
                          onChange={(e) => handleAttachmentChange(e, index)}
                          id={`attachment-input-${index}`}
                        />
                        <label htmlFor={`attachment-input-${index}`}>
                          <AttachFileIcon
                            sx={{
                              transform: 'rotate(45deg)',
                              fontSize: 40,
                              color: 'grey.700',
                              cursor: 'pointer',
                            }}
                          />
                        </label>
                      </>
                    )}
                  </CardContent>
                  <CardActions
                    sx={{
                      justifyContent: 'center',
                      backgroundColor: 'primary.main',
                      paddingBottom: 1,
                      width: '100%',
                    }}
                  >
                    <Typography variant="caption" color="white">
                      {attachment.type}
                    </Typography>
                  </CardActions>
                </Card>
              </Grid>
            ))}

            <Grid item sx={{ marginTop: '30px', marginBottom: '30px' }}>
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                  width: 120,
                  height: 90,
                  borderRadius: 3,
                  cursor: 'pointer',
                }}
                onClick={() => setModalOpen(true)} // Open modal on click
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ControlPointSharpIcon
                    sx={{ fontSize: 40, color: 'primary.main' }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>

      {/* Modal for Adding Attachment */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="add-attachment-title"
        aria-describedby="add-attachment-description"
      >
        <Box
          sx={{
            width: 300,
            bgcolor: 'background.paper',
            p: 4,
            borderRadius: 2,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Typography id="add-attachment-title" variant="h6" component="h2">
            Add Attachment
          </Typography>
          <TextField
            label="Attachment Name"
            variant="outlined"
            size="small"
            fullWidth
            value={attachmentName}
            onChange={(e) => setAttachmentName(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <FormControl fullWidth size="small">
            <InputLabel id="attachment-type-label">Type</InputLabel>
            <Select
              labelId="attachment-type-label"
              value={attachmentType}
              onChange={(e) => setAttachmentType(e.target.value)}
              label="Type"
            >
              <MenuItem value="Text">Text</MenuItem>
              <MenuItem value="Audio">Audio</MenuItem>
              <MenuItem value="Video">Video</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: 2 }}
            onClick={handleAddAttachment}
          >
            Add
          </Button>
        </Box>
      </Modal>

      <Button
        variant="contained"
        color="primary"
        sx={{ marginTop: 3 }}
        startIcon={
          lessonLoading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <SaveIcon />
          )
        }
        onClick={handleSaveLesson}
        disabled={lessonLoading} // Disable button while loading
      >
        {lessonLoading
          ? 'Saving...'
          : lessonData?.id || lessonIsSaved?.lesson_id
          ? 'Update Lesson'
          : 'Save Lesson'}
      </Button>
    </Box>
  );
};

export default Lesson;
