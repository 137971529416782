import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Lesson from '../Lesson';
import { deleteLesson, updateSortOrder } from '../../../../../helpers/Content';

const Section = ({
  sectionData,
  onSaveSection,
  onDeleteSection,
  setSections,
  onLessonSave,
  isEditing,
  onEdit,
  onCancelEdit,
  provided,
  buttonLoading,
  setButtonLoading,
  lessonLoading,
}) => {
  const [localSectionData, setLocalSectionData] = useState(sectionData || {});
  const [lessons, setLessons] = useState(sectionData?.lessons || []);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [lessonToDelete, setLessonToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const dragEndTimeout = useRef(null);

  useEffect(() => {
    setLocalSectionData(sectionData || {});
    setLessons(sectionData?.lessons || []);
  }, [sectionData]);

  const handleSaveSection = () => {
    const updatedSection = { ...localSectionData, lessons };
    onSaveSection(updatedSection);
  };

  const handleAddLesson = () => {
    const newLesson = {
      id: undefined,
      heading: '',
      description: '',
      attachments: [],
    };
    setLessons((prev) => [...prev, newLesson]);
  };

  const handleSaveLesson = async (lesson, index) => {
    try {
      const savedLesson = await onLessonSave(lesson, sectionData.id);
      const updatedLessons = [...lessons];
      updatedLessons[index] = savedLesson;
      setLessons(updatedLessons);
    } catch (error) {
      console.error('Error saving lesson:', error);
    }
  };

  const handleDeleteLesson = async () => {
    let lesson_to_delete_id = undefined;
    try {
      setIsDeleting(true);
      lesson_to_delete_id = sectionData.lessons[lessonToDelete].id;
      if (lesson_to_delete_id) {
        await deleteLesson(lesson_to_delete_id);
      }

      setSections((prev) =>
        prev.map((section) =>
          section.id === sectionData.id
            ? {
                ...section,
                lessons: section.lessons.filter(
                  (lesson) => lesson.id !== lesson_to_delete_id
                ),
              }
            : section
        )
      );
    } catch (error) {
      console.error('Error deleting lesson:', error);
    } finally {
      setIsDeleting(false);
      setDeleteDialogOpen(false);
      setLessonToDelete(null);
    }
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const reorderedLessons = Array.from(lessons);
    const [movedLesson] = reorderedLessons.splice(source.index, 1);
    reorderedLessons.splice(destination.index, 0, movedLesson);
    setLessons(reorderedLessons);

    // Clear any existing timeout and set a new one to debounce updateSortOrder call
    clearTimeout(dragEndTimeout.current);
    dragEndTimeout.current = setTimeout(() => {
      updateSortOrder(sectionData.id, reorderedLessons);
    }, 1000);
  };

  const isNewSection = !localSectionData.id;

  return (
    <Box
      {...provided.draggableProps}
      ref={provided.innerRef}
      sx={{
        padding: 2,
        border: '1px solid grey',
        borderRadius: 2,
        marginBottom: 2,
      }}
    >
      <Box {...provided.dragHandleProps}>
        {isEditing ? (
          <>
            <TextField
              label="Section Heading"
              variant="outlined"
              size="small"
              fullWidth
              value={localSectionData.heading || ''}
              onChange={(e) =>
                setLocalSectionData({
                  ...localSectionData,
                  heading: e.target.value,
                })
              }
              sx={{ marginBottom: 2 }}
            />
            <ReactQuill
              value={localSectionData.description || ''}
              onChange={(value) =>
                setLocalSectionData({ ...localSectionData, description: value })
              }
              theme="snow"
              style={{ minHeight: '100px', marginBottom: '16px' }}
            />

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable
                droppableId={`lessons-${localSectionData.id || 'new'}`}
              >
                {(provided) => (
                  <Box {...provided.droppableProps} ref={provided.innerRef}>
                    {lessons.map((lesson, index) => (
                      <Draggable
                        key={lesson.id || index}
                        draggableId={`lesson-${lesson?.id || index}`}
                        index={index}
                      >
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              margin: '16px 0',
                              padding: '16px',
                              border: '1px solid lightgray',
                              borderRadius: 2,
                              position: 'relative',
                            }}
                          >
                            <Lesson
                              lessonData={lesson}
                              lessonLoading={lessonLoading}
                              onSave={(updatedLesson) =>
                                handleSaveLesson(updatedLesson, index)
                              }
                            />
                            <IconButton
                              sx={{ position: 'absolute', top: 8, right: 8 }}
                              onClick={() => {
                                setLessonToDelete(index);
                                setDeleteDialogOpen(true);
                              }}
                              color="error"
                            >
                              <CloseIcon />
                            </IconButton>
                            <Divider sx={{ marginTop: 2 }} />
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>

            <Button
              variant="contained"
              color="secondary"
              onClick={handleAddLesson}
              disabled={isNewSection}
            >
              Add Lesson
            </Button>

            <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
              <Button onClick={onCancelEdit}>Cancel</Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  buttonLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <SaveIcon />
                  )
                }
                onClick={handleSaveSection}
                disabled={buttonLoading}
              >
                {buttonLoading
                  ? 'Saving...'
                  : isNewSection
                  ? 'Save Section'
                  : 'Update Section'}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h6">{sectionData.heading}</Typography>
            <div
              dangerouslySetInnerHTML={{ __html: sectionData.description }}
              style={{ marginTop: '8px', fontSize: '0.875rem' }}
            />

            {/* Display the first 2-3 lesson headings or a message if no lessons */}
            <Box mt={2}>
              {lessons.length > 0 ? (
                <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
                  <b>{`Lessons: `}</b>
                  {lessons
                    .slice(0, 3)
                    .map((lesson) => lesson.heading)
                    .join(', ')}
                  ...
                </Typography>
              ) : (
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: 1, fontStyle: 'italic' }}
                >
                  No lessons available.
                </Typography>
              )}
            </Box>

            <Box mt={2} display="flex" gap={2}>
              <IconButton color="primary" onClick={onEdit}>
                <EditIcon />
              </IconButton>
              <IconButton
                color="error"
                onClick={() => onDeleteSection(sectionData.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </>
        )}
      </Box>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this lesson?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          {isDeleting ? (
            <CircularProgress size={24} />
          ) : (
            <Button color="error" onClick={handleDeleteLesson}>
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Section;
