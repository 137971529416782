import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { fetchLessonData } from '../../../../helpers/Course/CourseDetails';
import {
  getQuizByLesson,
  addQuiz,
  updateQuiz,
  deleteQuiz,
  updateAssignmentSortOrder,
} from '../../../../helpers/Quiz';
import { useSelector } from 'react-redux';
import AssignmentsList from '../Assignments/AssignmentList';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Assignments = ({ course: { course } }) => {
  const [sections, setSections] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedLesson, setSelectedLesson] = useState('');
  const [assignments, setAssignments] = useState([]);
  const [expandedAssignments, setExpandedAssignments] = useState({}); // Track expanded state of assignments
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [assignmentToDelete, setAssignmentToDelete] = useState(null);
  const [selectedLessonName, setSelectedLessonName] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [assignmentName, setAssignmentName] = useState('');
  const [isAssignmentActive, setIsAssignmentActive] = useState(true);
  const [editingAssignmentId, setEditingAssignmentId] = useState(null);
  const [fetchStatus, setFetchStatus] = useState({}); // Track fetch status for each lesson
  const [isDragEnabled, setIsDragEnabled] = useState(true); // Boolean for drag-and-drop control

  const { user_id } = useSelector((state) => state?.generalInformation);
  const courseIdRef = useRef(course.id);

  useEffect(() => {
    const fetchSections = async () => {
      setIsFetching(true);
      try {
        const data = await fetchLessonData(courseIdRef.current);
        if (data.length > 0) {
          setSections(data);
          setSelectedSection(data[0]?.id);
          setSelectedLesson(data[0]?.lessons[0]?.id);
          setSelectedLessonName(data[0]?.lessons[0]?.heading);
        }
      } catch (error) {
        console.error('Error fetching sections:', error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchSections();
  }, []);

  useEffect(() => {
    if (selectedSection) {
      const section = sections.find((s) => s.id === selectedSection);
      if (section && section.lessons.length > 0) {
        setSelectedLesson(section.lessons[0].id);
        setSelectedLessonName(section.lessons[0].heading);
      } else {
        setSelectedLesson('');
        setSelectedLessonName('');
      }
    }
  }, [selectedSection, sections]);

  const handleQuizFetch = async () => {
    if (!selectedLesson) return;
    try {
      const quizData = await getQuizByLesson(selectedLesson);
      setAssignments(quizData);
      setFetchStatus((prevStatus) => ({
        ...prevStatus,
        [selectedLesson]: true, // Mark lesson as fetched
      }));
    } catch (error) {
      console.error('Error fetching quiz data:', error);
    }
  };

  const handleCreateAssignment = async () => {
    if (!selectedLesson || !assignmentName) return;
    const newAssignment = {
      assignment_name: assignmentName,
      campaign_id: selectedLesson,
      parent_id: courseIdRef.current,
      is_active: isAssignmentActive,
      created_by: user_id,
      updated_by: user_id,
    };

    try {
      const createdAssignment = await addQuiz(newAssignment);

      if (fetchStatus[selectedLesson]) {
        setAssignments((prev) => {
          if (
            !prev.find((assignment) => assignment.id === createdAssignment.id)
          ) {
            return [...prev, createdAssignment];
          }
          return prev;
        });
      } else {
        const quizData = await getQuizByLesson(selectedLesson);
        if (
          !quizData.find((assignment) => assignment.id === createdAssignment.id)
        ) {
          setAssignments([...quizData, createdAssignment]);
        } else {
          setAssignments(quizData);
        }

        setFetchStatus((prevStatus) => ({
          ...prevStatus,
          [selectedLesson]: true,
        }));
      }

      setModalOpen(false);
      setAssignmentName('');
      setIsAssignmentActive(true);
    } catch (error) {
      console.error('Error creating assignment:', error);
    }
  };

  const handleEditAssignment = (assignment) => {
    setEditingAssignmentId(assignment.id);
    setAssignmentName(assignment.assignment_name);
    setIsAssignmentActive(assignment.is_active);
    setModalOpen(true);
  };

  const handleUpdateAssignment = async () => {
    if (!editingAssignmentId || !assignmentName) return;

    const updatedAssignment = {
      id: editingAssignmentId,
      assignment_name: assignmentName,
      is_active: isAssignmentActive,
      updated_by: user_id,
    };

    try {
      await updateQuiz(editingAssignmentId, updatedAssignment);
      setAssignments((prev) =>
        prev.map((assignment) =>
          assignment.id === editingAssignmentId
            ? {
                ...assignment,
                assignment_name: assignmentName,
                is_active: isAssignmentActive,
              }
            : assignment
        )
      );
      setModalOpen(false);
      setEditingAssignmentId(null);
      setAssignmentName('');
      setIsAssignmentActive(true);
    } catch (error) {
      console.error('Error updating assignment:', error);
    }
  };

  const handleDeleteAssignment = async () => {
    if (!assignmentToDelete) return;
    try {
      await deleteQuiz(assignmentToDelete.id);
      setAssignments((prevAssignments) =>
        prevAssignments.filter(
          (assignment) => assignment.id !== assignmentToDelete.id
        )
      );
      setDeleteDialogOpen(false);
      setAssignmentToDelete(null);
    } catch (error) {
      console.error('Error deleting assignment:', error);
    }
  };

  // Handle when the collapse state of an assignment changes
  const handleDragEnd = async (result) => {
    if (!isDragEnabled) return; // Prevent drag-and-drop if disabled
    const { destination, source } = result;
    if (!destination) return;

    const reorderedAssignments = Array.from(assignments);
    const [movedItem] = reorderedAssignments.splice(source.index, 1);
    reorderedAssignments.splice(destination.index, 0, movedItem);

    setAssignments(reorderedAssignments);

    const sortedAssignments = reorderedAssignments.map((assignment, index) => ({
      id: assignment.id,
      campaign_id: assignment.campaign_id,
      sort_order: index,
    }));

    try {
      await updateAssignmentSortOrder(course.id, sortedAssignments);
    } catch (error) {
      console.error('Error updating sort order:', error);
    }
  };
  return (
    <Box>
      {isFetching ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '500px',
          }}
        >
          <CircularProgress size={150} color="primary" />
        </Box>
      ) : (
        <>
          <Typography variant="h4">Assignments for {course.name}</Typography>

          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Select Section</InputLabel>
            <Select
              value={selectedSection}
              onChange={(e) => setSelectedSection(e.target.value)}
              label="Select Section"
            >
              {sections.map((section) => (
                <MenuItem key={section.id} value={section.id}>
                  {section.heading}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            disabled={!selectedSection}
          >
            <InputLabel>Select Lesson</InputLabel>
            <Select
              value={selectedLesson}
              onChange={(e) => setSelectedLesson(e.target.value)}
              label="Select Lesson"
            >
              {selectedSection &&
                sections
                  .find((section) => section.id === selectedSection)
                  ?.lessons.map((lesson) => (
                    <MenuItem key={lesson.id} value={lesson.id}>
                      {lesson.heading}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>

          <Button
            onClick={handleQuizFetch}
            variant="contained"
            color="primary"
            sx={{ marginTop: 2 }}
          >
            Filter
          </Button>

          <Button
            onClick={() => setModalOpen(true)}
            variant="contained"
            color="secondary"
            sx={{ marginTop: 2, marginLeft: 2 }}
          >
            Add Assignment{' '}
            {selectedLessonName && ` (For Lesson ${selectedLessonName})`}
          </Button>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="assignments-list">
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{ marginTop: 2 }}
                >
                  {console.log('Drag and Drop disabled', isDragEnabled)}
                  {assignments.map((assignment, index) => (
                    <Draggable
                      key={assignment.id}
                      draggableId={assignment.id.toString()}
                      index={index}
                      isDragEnabled={isDragEnabled}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...(isDragEnabled ? provided.dragHandleProps : {})} // Only add dragHandleProps if enabled
                        >
                          <AssignmentsList
                            assignments={[assignment]} // Render single assignment for each draggable item
                            onEdit={handleEditAssignment}
                            onDelete={() => {
                              setAssignmentToDelete(assignment);
                              setDeleteDialogOpen(true);
                            }}
                            setIsDragEnabled = {setIsDragEnabled}
                          />
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>

          {/* Modal for adding/updating assignment */}
          <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
            <DialogTitle>
              {editingAssignmentId ? 'Edit Assignment' : 'Add New Assignment'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enter the name of the assignment and other relevant details.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="Assignment Name"
                type="text"
                fullWidth
                variant="outlined"
                value={assignmentName}
                onChange={(e) => setAssignmentName(e.target.value)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAssignmentActive}
                    onChange={(e) => setIsAssignmentActive(e.target.checked)}
                  />
                }
                label="Active"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setModalOpen(false)}>Cancel</Button>
              <Button
                onClick={
                  editingAssignmentId
                    ? handleUpdateAssignment
                    : handleCreateAssignment
                }
                color="primary"
              >
                {editingAssignmentId ? 'Update' : 'Create'}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Delete Confirmation Dialog */}
          <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
          >
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this assignment? This action
                cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setDeleteDialogOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={handleDeleteAssignment} color="error">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default Assignments;
